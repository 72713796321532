import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
}

interface Payment {
  _id?: string;
  paid?: number;
  cash?: number;
  card?: number;
  transfer?: number;
  app?: number;
  cardType?: string;
  transferType?: string;
}

interface Refund {
  amount?: number;
}

interface Treatments {
  quantity?: number;
  unitPrice?: number;
  treatmentFee?: number;
  vatCode?: string;
  vat?: number;
  revenueTax?: number;
  name?: string;
  _id?: string;
  paymentMethod?: string
}

interface Doctor {
  _id?: string;
  name?: string;
}

interface Location {
  _id?: string;
  name?: string;
  typeid?: string;
  oldid?: string;
  status?: number;
  address?: string;
}

interface Patient {
  _id?: string;
  name?: string;
  id?: string;
  address?: string;
  email?: string;
  remain?: number;
}

export interface IGeneralDebtTable {
  _id?: string;
  dateEndProcedure?: string; // ISO date string
  endProcedureTreatmentName?: string;
  dateStartProcedure?: string[]; // Array of ISO date strings
  totalPaid?: number;
  totalNetAmount?: number;
  totalRefund?: number;
  totalRemain?: number;
  __v?: number;
  date?: string; // ISO date string
  doctor?: Doctor;
  location?: Location;
  netAmount?: number;
  patient?: Patient;
  payment?: Payment;
  refund?: Refund;
  treatments?: Treatments;
}

