/* eslint-disable @typescript-eslint/no-explicit-any */
import { IIndustry } from 'app/api/industry/model/management'
import { requestGetItemManagement } from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { IItemManagement } from 'app/api/item/model/management'
import { ILevelSkill } from 'app/api/skill/model/management'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import {
  appendIds,
  convertStatusFilterToStatusEnum,
  convertStatusToFilterStatus,
} from 'app/common/helpers'
import { ResponseTypeV2 } from 'app/common/model'
import { ButtonUpdate } from 'app/components/common/ButtonUpdate'
import { ACCOUNTING_TREATMENT_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import {
  ExpandedTableValue,
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useGetIndustryList } from 'app/react-query/hook/industry'
import { useGetLevelSkillManagementList } from 'app/react-query/hook/level-skill'
import { IResponseQueryList } from 'app/react-query/model/common'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefineValueTypeItem, IDataTableItemManagement } from './common-model'
import { IFilterResponseTreatmentManagement } from './components/FilterItemManagement/type'
import { DeleteItem } from './components/modal/treatment-management/DeleteItem'

interface IListSkill {
  id: string
  industryId?: string
  industryName?: string
  skillId?: string
  skillName?: string
  levelSkillName?: string
  levelSkillId?: string
  levelSkillColor?: string
}

export const useTableWeeklyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()
  const { flattenDataList } = usePagination()

  const [listSkill, setListSkill] = useState<IListSkill[]>()
  const { data: industryData, isLoading: loadingIndustry } =
    useGetIndustryList()
  const industries = useMemo(() => {
    const flattenData: IResponseQueryList<IIndustry[]> =
      flattenDataList(industryData)
    return flattenData?.data ?? []
  }, [industryData])

  const { data: levelSkillData, isLoading: loadingLevelSkill } =
    useGetLevelSkillManagementList()

  const levelSkills = useMemo(() => {
    const flattenData: IResponseQueryList<ILevelSkill[]> =
      flattenDataList(levelSkillData)
    return flattenData?.data ?? []
  }, [levelSkillData])
  const skills: IListSkill[] = []
  Array.isArray(levelSkills) &&
    levelSkills.map(levelSkill =>
      industries.map(industry =>
        industry?.skills?.map(skill =>
          skills.push({
            id: `${appendIds([
              skill._id ?? '',
              industry._id ?? '',
              levelSkill._id ?? '',
            ])}`,
            industryId: industry._id,
            industryName: industry.name,
            skillId: skill._id,
            skillName: skill.name,
            levelSkillName: levelSkill.name,
            levelSkillId: levelSkill._id,
            levelSkillColor: levelSkill.color,
          }),
        ),
      ),
    )

  useEffect(() => {
    setListSkill(skills)
  }, [skills.length, industries.length, levelSkills.length])

  const handleChangeFilter = (filter: IFilterResponseTreatmentManagement) => {
    fetch(tableData.pagination, filter)
  }

  const getDataTable = async (pagination: Pagination, filter) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataTableItemManagement>
    try {
      const params = {
        keyword: filter?.keyword ?? undefined,
        status: filter?.status
          ? convertStatusFilterToStatusEnum(filter?.status)
          : undefined,
        type: 'treatment',
        page: pagination?.current,
        pagesize: pagination?.pageSize,
      }
      const response: ResponseTypeV2<IItemManagement[]> =
        await requestGetItemManagement(params)

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successGetData,
        )
      ) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          const key = index + 1
          return {
            ordinalNumber: startIndex + index + 1,
            _id: item?._id,
            key: key,
            index: startIndex + index + 1,
            name: item?.name ?? '-',
            code: item?.code ?? '-',
            executeTime: item?.executeTime ?? '-',
            doctorTime: item?.doctorTime ?? '-',
            chairTime: item?.chairTime ?? '-',
            skillsInfo: item?.skillsInfo ?? [],
            itemIds: item?.itemIds ?? [],
            childrenUsingMeasurementUnitInfo:
              item?.childrenUsingMeasurementUnitInfo,
            type: item?.type,
            service: item?.type ? `${DefineValueTypeItem[item?.type]}` : '-',
            status: item?.status
              ? convertStatusToFilterStatus(item?.status as StatusEnum)
              : undefined,
          }
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IDataTableItemManagement>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataTable })

  const exportExcel = async () => {
    try {
      // do something
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const fetchNewData = () => {
    fetch(tableData.pagination, props)
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataTableItemManagement

    return [
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.treatment_management_content_treatment_code),
        fixed: 'left',
        key: 'code',
        className: 'center',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return record.code
        },
      }),
      convertColumnTable<TypeColumn>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.name}
              classNameText={'medium-text'}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.treatment_management_content_treatment_execute_time),
        key: 'executeTime',
        className: 'center',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return record.executeTime
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.treatment_management_content_treatment_doctor_time),
        key: 'doctorTime',
        className: 'center',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return record.doctorTime
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.treatment_management_content_treatment_chair_time),
        key: 'chairTime',
        className: 'center',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record) => {
          return record.chairTime
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.status),
        fixed: 'right',
        key: 'status',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                <ButtonUpdate
                  title={t(R.strings.update)}
                  path={ACCOUNTING_TREATMENT_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH.replace(
                    ':type',
                    record.type,
                  ).replace(':id', record._id)}
                />
                <DeleteItem
                  id={record._id}
                  fetchTable={fetchNewData}
                  name={record?.name}
                  type={record?.type}
                />
              </>
            }
          />
        ),
      }),
    ]
  }, [t, expandedRowKeys, fetchNewData])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    exportExcel,
    handleResetExpand,
    fetch,
    fetchLoading,
    listSkill,
    handleChangeFilter,
    fetchNewData,
    loadingIndustry,
    loadingLevelSkill,
  }
}
