import { IRefererReport } from 'app/api/marketing/referer-report/model'
import { Pagination } from 'parkway-web-common'

export interface IDataTable {
  [key: string]: string
}

export interface IFilterDataTableReport {
  keyword?: string
}
export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}
