/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import {
  formatMoney,
  initialPagination,
  moment,
  Pagination,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable, IFilter } from './type'
import { getArray } from 'app/components/tables/common-table/constant'
import { IDataReport15 } from 'app/api/report/model/report-15'
import { useGetFilterReport15 } from 'app/react-query/hook/report'
import { usePagination } from 'app/hook'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { FunctionPermissionEnum, ModulePermissionEnum } from 'app/common/permission-module'

export const useHook = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilter>({
    year: moment().format('YYYY'),
  })
  const [pagination, setPagination] = useState<Pagination>(initialPagination)

  const { data: dataInfinity, isLoading } = useGetFilterReport15({
    year: filter.year,
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
  })

  const { flattenDataList } = usePagination()

  const { isHavePermissionFunctionAndGoBack, user } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.REPORT,
      FunctionPermissionEnum.REPORT_READ_REPORT_15,
      R.strings.report_15,
    )
  }, [user])

  const onChangeFilter = (values: IFilter) => {
    setFilter(values)
    setPagination({
      ...pagination,
      current: 1,
    })
  }

  const onChangeTable = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const dataFlatten: IResponseQueryList<IDataReport15[]> = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      let newResponse: IDataTable = {
        ...item,
        index: index + 1,
      }

      item?.months?.forEach(itemMonth => {
        newResponse = {
          ...newResponse,
          [`actual-month-${itemMonth?.month}`]: itemMonth?.actualRevenue,
          [`revenue-month-${itemMonth.month}`]: itemMonth?.estimateRevenue,
        }
      })

      return newResponse
    })
  }, [dataInfinity])

  const paginationData = useMemo(() => {
    return {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    }
  }, [dataInfinity, pagination])

  const columns = useMemo(() => {
    const listMonthOfYear = getArray(12).map(month => {
      return {
        key: `month-${month}`,
        title: t(R.strings.month_no, { month }),
        children: [
          convertColumnTable<any>({
            title: t(R.strings.actual_revenue),
            key: `actual-month-${month}`,
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(
                    record?.[`actual-month-${month}`] ?? '0',
                  )}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
          convertColumnTable<any>({
            title: t(R.strings.pending_revenue),
            key: `revenue-month-${month}`,
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(
                    record?.[`revenue-month-${month}`] ?? '0',
                  )}
                  fontWeight="medium"
                  opacity="0.5"
                />
              )
            },
          }),
        ],
      }
    })

    return [
      convertColumnTable<{ index: number }>({
        title: '#',
        key: 'index',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'left',
        render: (_, record) => {
          return <BaseText children={record?.index} fontWeight="medium" />
        },
      }),
      convertColumnTable<IDataReport15>({
        key: 'clinicName',
        title: t(R.strings.clinic),
        classNameWidthColumnOverwrite: 'big-column',
        fixed: 'left',
        render: (_, record) => {
          return <BaseText children={record?.clinicName} fontWeight="medium" />
        },
      }),
      ...listMonthOfYear,
      {
        key: 'total',
        title: t(R.strings.total),
        children: [
          convertColumnTable<IDataReport15>({
            key: 'total',
            title: t(R.strings.actual_revenue),
            fixed: 'right',
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(record?.total?.totalPayment ?? 0)}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
          convertColumnTable<IDataReport15>({
            key: 'total',
            title: t(R.strings.pending_revenue),
            fixed: 'right',
            render: (_, record) => {
              return (
                <BaseText
                  children={formatMoney(record?.total?.totalRevenue ?? 0)}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
        ]
      }
    ]
  }, [t])

  return {
    filter,
    pagination: paginationData,
    onChangeTable,
    onChangeFilter,
    data,
    columns,
    isLoading,
  }
}
