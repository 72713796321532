import R from 'app/assets/R'
import { ButtonCreate } from 'app/components/common/ButtonCreate'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { PROFILE_MANAGEMENT_CREATE_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { t } from 'i18next'
import FilterJobTitleComponent from './components/FilterJobTitle'
import { useHookTable } from './hook'
const ProfileManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    handleChangeFilterJobTitle,
    handleExport,
  } = useHookTable()
  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.profile_content_management}
        isExport
        onPressExport={handleExport}
        rightComponent={
          <ButtonCreate
            title={t(R.strings.profile_content_add)}
            path={PROFILE_MANAGEMENT_CREATE_PATH}
          />
        }
      />
      <S.RootWrapper>
        <FilterManagement
          handleChangeFilter={handleChangeFilter}
          filterData={filter}
          otherFilterComponent={
            <FilterJobTitleComponent
              onChangeFilter={handleChangeFilterJobTitle}
            />
          }
          xlSize={12}
        />
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default ProfileManagementContainer
