import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import { IResponseFilter } from 'app/components/tables/BaseTableManagement/FilterSearchAppointmentTable/type'
import { StatusTableEnum } from 'app/common/enum'
import { IBaseTableCommon } from 'app/components/tables/common-table/model'

export interface IGeneralProfileManagementTable extends IBaseTableCommon {
  ordinalNumber?: number
  _id?: string
  name?: string
  mobile?: string
  email?: string
  employeeid?: string
  unitGroupId?: string
  workinfos?: string
  unitTypeId?: string
  workinfoakas?: string
  oldid?: string
  description?: string
  status?: StatusTableEnum
  createddate?: string
  jobTitleAkaName?: string
  jobTitleNameMain?: string
  jobtitle?: IJobTitle[]
  avatar?: string
  unitFilteredArea?: string
  unitFilteredClinic?: string
  unitFilteredDepartment?: string
}

export enum StatusProfileEnum {
  active = 'active',
  // error = 'error',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum ActionAccountEnum {
  create = 'create',
  select_id = 'select_id',
  non = 'non',
}

export interface IFilterProfile extends IResponseFilter {
  group?: string
  jobTitles?: string[]
}
