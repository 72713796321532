import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { Report022Container } from 'app/containers/Report/Report022'

const Report022PageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_22)}</PageTitle>
      <Report022Container />
    </>
  )
}
const Report022Page = memo(Report022PageCpn, isEqual)

export default Report022Page
