import {
  requestGetProfileManagement,
  requestExportProfile,
} from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import {
  IParamsGetProfileManagement,
  IResponseGetProfileManagement,
} from 'app/api/profile/model/management'
import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ButtonUpdate } from 'app/components/common/ButtonUpdate'
import { PROFILE_MANAGEMENT_UPDATE_PATH } from 'app/components/router/route-path'
import { initResponseFilterTableManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { FONT_WEIGHT, Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from './components/Delete'
import { IFilterProfile, IGeneralProfileManagementTable } from './type'
import * as S from './styles'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseButton } from 'app/components/common/BaseButton'
import { MoreOutlined } from '@ant-design/icons'
import { BaseSpace } from 'app/components/common/BaseSpace'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IFilterProfile>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.PROFILE_MANAGEMENT_READ,
      R.strings.profile,
    )
  }, [t])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IFilterProfile,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralProfileManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetProfileManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
        group: newFilter?.group ?? filter?.group,
        jobtitleid: newFilter?.jobTitles ?? filter?.jobTitles,
      }

      if (status !== undefined) {
        params = { ...params, status }
      }

      const response: IResponseGetProfileManagement =
        await requestGetProfileManagement(params)

      if (
        isEqual(
          response?.msgcode,
          ProfileMessageCode.ProfileManagement.successGetData,
        )
      ) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          return {
            key: index + 1,
            ordinalNumber: startIndex + index + 1,
            name: item?.name ?? '-',
            employeeid: item?.employeeid,
            email: item?.email ?? '-',
            mobile: item?.mobile ?? '-',
            unitTypeId: item?.unitTypeId ?? '-',
            jobTitleNameMain: item?.jobTitleNameMain ?? '-',
            unitFilteredArea: item?.unitFilteredArea ?? '-',
            unitFilteredClinic: item?.unitFilteredClinic ?? '-',
            unitFilteredDepartment: item?.unitFilteredDepartment ?? '-',
            jobTitleAkaName: item?.jobTitleAkaName ?? '-',
            description: item?.description ?? '-',
            _id: item?._id ?? '-',
            status: convertStatusToFilterStatus(item?.status),
            avatar: item?.avatar,
          }
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pagesize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralProfileManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilterJobTitle = ({ group, jobTitles }: IFilterProfile) => {
    const { group: groupOld, jobTitles: jobTitlesOld } = filter
    if (
      !isEqual(
        { group: groupOld, jobTitles: jobTitlesOld },
        { group, jobTitles },
      )
    ) {
      const newFilter = {
        ...filter,
        group,
        jobTitles,
      }
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const handleChangeFilter = ({ search, status }: IFilterProfile) => {
    const { search: searchOld, status: statusOld } = filter
    if (
      !isEqual({ search: searchOld, status: statusOld }, { search, status })
    ) {
      const newFilter = {
        ...filter,
        search,
        status,
      }
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const handleExport = async () => {
    const response = await requestExportProfile()
    const url = response?.data?.Url

    url && window.open(url, '_bank')
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          let jobTitles: string[] = []

          if (record?.jobTitleNameMain && !isEmpty(record?.jobTitleNameMain)) {
            jobTitles = jobTitles.concat([record?.jobTitleNameMain])
          }

          if (record?.jobTitleAkaName && !isEmpty(record?.jobTitleAkaName)) {
            jobTitles = jobTitles.concat([record?.jobTitleAkaName])
          }

          const jobTitle = jobTitles?.length ? jobTitles.join(' & ') : undefined
          return (
            <BaseRow gutter={[10, 10]} align={'middle'} wrap={false}>
              <BaseCol>
                <BaseAvatar
                  alt={record?.name}
                  src={record?.avatar}
                  style={{
                    width: 40,
                    height: 40,
                    alignItems: 'center',
                    justifyItems: 'center',
                    fontSize: 16,
                    display: 'flex',
                  }}
                />
              </BaseCol>

              <BaseCol>
                <RenderValueTable
                  value={record.name}
                  type="Main"
                  styleText={{ fontWeight: FONT_WEIGHT.bold }}
                />
                {jobTitle ? (
                  <S.TextJobTitleInProfileName>
                    {jobTitle}
                  </S.TextJobTitleInProfileName>
                ) : null}
              </BaseCol>
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.id),
        key: 'employeeid',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.employeeid} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: `${t(R.strings.profile_content_email)}/${t(
          R.strings.profile_content_phone,
        )}`,
        key: 'email',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <BaseSpace size={5}>
            <RenderValueTable value={`${record.email}`} type="Base" />
            <RenderValueTable value={`${record?.mobile}`} type="Base" />
          </BaseSpace>
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_department),
        key: 'unitFilteredDepartment',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.unitFilteredDepartment ?? '-'} type="Base" />
        ),
      }),
      
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_area),
        key: 'unitFilteredArea',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.unitFilteredArea} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.profile_content_clinic),
        key: 'unitFilteredClinic',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record.unitFilteredClinic} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        className: 'title-right',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            isNotShowThreeDot
          />
        ),
      }),
      convertColumnTable<IGeneralProfileManagementTable>({
        title: '',
        key: 'status',
        className: 'title-right',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <>
            <BasePopover
              trigger={'click'}
              content={
                <BaseSpace>
                  <ButtonUpdate
                    title={t(R.strings.update)}
                    path={`${PROFILE_MANAGEMENT_UPDATE_PATH.replace(
                      ':id',
                      record._id ?? '',
                    )}`}
                  />
                  {!isEqual(record?.status, StatusTableEnum.deleted) && (
                    <Delete
                      id={record?._id}
                      fetchTable={fetchNewData}
                      name={record?.name}
                    />
                  )}
                </BaseSpace>
              }
            >
              <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
            </BasePopover>
          </>
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    handleChangeFilterJobTitle,
    handleExport,
  }
}
