import { IBaseTableReport } from 'app/components/tables/BaseTableReport/type'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IGeneralMarketingPlanTable<T> extends IBaseTableReport<T> {
  service?: string
  month?: string
  week?: string

  budget?: number
  spentActual?: number
  percentBudgetUsing?: number
  qTechPlaning?: number
  qTechActual?: number
  qTechAchievement?: number
  cpqlPlaning?: number
  cpqlActual?: number
  appointmentSchedule?: number
  checkInSchedule?: number

  appointmentScheduleTotal?: number
  checkInScheduleTotal?: number
  budgetTotal?: number
  spentActualTotal?: number
  percentBudgetUsingTotal?: number
  qTechPlaningTotal?: number
  qTechActualTotal?: number
  qTechAchievementTotal?: number
  cpqlPlaningTotal?: number
  cpqlActualTotal?: number
}
