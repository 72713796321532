import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { FilterManagement } from 'app/components/tables/BaseTableManagement/FilterManagementTable'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { Create } from './components/Create'
import { useHookTable } from './hook'
import styled from 'styled-components'
const UnitManagementContainer = () => {
  const {
    tableData,
    columns,
    fetchNewData,
    filter,
    handleChangeFilter,
    handleExpand,
    expandedRowKeys,
    handleTableChange,
  } = useHookTable()

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.unit_content_management}
        isExport
        rightComponent={<Create fetchTable={fetchNewData} />}
      />
      <S.RootWrapper>
        <BaseRow>
          <FilterManagement
            handleChangeFilter={handleChangeFilter}
            filterData={filter}
          />
        </BaseRow>
        <TableWrapper
          columns={columns}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            defaultExpandAllRows: true,
            expandIcon: () => null,
            rowExpandable: record => {
              return !!record?.children?.length
            },
          }}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          onChange={handleTableChange}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default UnitManagementContainer

const TableWrapper = styled(BaseTableManagement)`
  // .name-column {
  //   min-width: 450px !important;
  // }
  // .ant-table-row-level-1 .name-column {
  //   padding-left: 60px;
  // }
  // .ant-table-row-level-2 .name-column {
  //   padding-left: 120px;
  // }
  // .ant-table-row-level-3 .name-column {
  //   padding-left: 240px;
  // }
`
