import R from 'app/assets/R'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, initialPagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralWarehouseGoodsIssueManagementTable } from './type'

import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { BaseCol } from 'app/components/common/BaseCol'

import { MessageCode } from 'app/api/inventory-new/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { IMAGE_ACTION } from 'app/common/config'
import { ActionButton, ActionDropdown, StatusButton } from './styles'
import { DATE } from 'app/common/constants'

import { useNavigate } from 'react-router'
import {
  requestExportWarehouseGoodsIssueManagement,
  requestGetWarehouseGoodsIssueManagement,
} from 'app/api/inventory-new/api/warehouse-goods-issue'
import {
  IParamsGetWarehouseGoodsIssueManagement,
  IResponseGetWarehouseGoodsIssueManagement,
} from 'app/api/inventory-new/model/warehouse-goods-issue'
import {
  GoodsIssueActionFromStatus,
  GoodsIssueActionIcon,
  GoodsIssueActionName,
  GoodsIssueActionPath,
  GoodsIssueStatusColor,
  GoodsIssueStatusName,
} from './constant'
import { AuthorInfo, AuthorJobName, AuthorName, AuthorUserLeft, TableAvatar } from 'app/containers/CustomerCare/SearchAppointment/styles'
interface IResponseFilter {
  search?: string
  status?: number
}

const initResponseFilterTableManagement = {}
export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.WAREHOUSE_READ,
      R.strings.role,
    )
  }, [])

  const onClickExport = async params => {
    try {
      const response = await requestExportWarehouseGoodsIssueManagement({
        keyword: params?.search ?? filter?.search,
      })
      const url = `${IMAGE_ACTION.IMAGE_SERVER}/${response?.data?.Url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralWarehouseGoodsIssueManagementTable>
    try {
      const status = newFilter?.status ?? filter?.status
      let params: IParamsGetWarehouseGoodsIssueManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, status }

      const response: IResponseGetWarehouseGoodsIssueManagement =
        await requestGetWarehouseGoodsIssueManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              ...item,
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralWarehouseGoodsIssueManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: '#',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.warehouse_goods_issue_code),
        key: 'code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.date_time),
        key: 'createAtDate',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.createAtDate).format(DATE.YYYY_MM_DD_HH_MM)}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.warehouse_goods_issue_reason),
        key: 'reason',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.reason} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.warehouse_export),
        key: 'warehouseIssue',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.warehouseIssue?.name} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.warehouse_import),
        key: 'warehouseReceive',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record?.warehouseReceive?.name}
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.person_request),
        key: 'issuer',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <>
          {record?.issuer && (
            <AuthorUserLeft>
              <TableAvatar
                alt={record?.issuer?.name}
                src={record?.issuer?.avatar}
              />
              <AuthorInfo>
                <AuthorName>{record?.issuer?.name}</AuthorName>
                <AuthorJobName>{record?.issuer?.jobTitle}</AuthorJobName>
              </AuthorInfo>
            </AuthorUserLeft>
          )}
        </>
        ),
      }),
      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.note),
        key: 'note',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.note} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const statusColor = GoodsIssueStatusColor[Number(record?.status)]

          return (
            <StatusButton
              $color={statusColor?.color}
              $background={statusColor?.background}
            >
              {t(GoodsIssueStatusName[Number(record?.status)])}
            </StatusButton>
          )
        },
      }),

      convertColumnTable<IGeneralWarehouseGoodsIssueManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => {
          return (
            <ValueTableWrapper
              justify={'end'}
              align={'middle'}
              gutter={[10, 10]}
              wrap={false}
            >
              <BaseCol>
                <ActionDropdown
                  actionComponent={
                    <>
                      {GoodsIssueActionFromStatus[Number(record?.status)]?.map(
                        key => {
                          const IconComponent = GoodsIssueActionIcon[key]
                          const ActionName = GoodsIssueActionName[key]
                          return (
                            <ActionButton
                              icon={<IconComponent />}
                              onClick={() =>
                                navigate(
                                  `${GoodsIssueActionPath[key]?.replace(
                                    ':id',
                                    record._id ?? '',
                                  )}`,
                                )
                              }
                            >
                              {t(ActionName)}
                            </ActionButton>
                          )
                        },
                      )}
                    </>
                  }
                />
              </BaseCol>
            </ValueTableWrapper>
          )
        },
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onClickExport,
  }
}
