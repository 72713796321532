import R from 'app/assets/R'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination, ResponseType, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from './components/Delete'
import { IGeneralSkillManagementTable } from './type'
import {
  IParamsGetSkillManagement,
  IResponseGetSkillManagement,
} from '../../../api/skill/model/management'
import { requestGetSkillManagement } from 'app/api/skill'
import { SkillMessageCode } from 'app/api/skill/constant'
import { Update } from './components/Update'
import { convertHtmlEntitiesToString } from 'app/helpers/string.helper'
import { IIndustry, IRangeExperience } from 'app/api/degree/model/management'
import { ILevelSkill } from 'app/api/level-skill/model/management'
import { requestGetAllIndustryManagement } from 'app/api/industry'
import { IndustryMessageCode } from 'app/api/industry/constant'
import { requestGetAllLevelSkillManagement } from 'app/api/level-skill'
import { LevelSkillMessageCode } from 'app/api/level-skill/constant'
import { requestGetAllRangeExperienceManagement } from 'app/api/range-experience'
import { RangeExperienceMessageCode } from 'app/api/range-experience/constant'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { convertArrayStatusFilterToArrayStatusNumber, convertStatusToFilterStatus } from 'app/common/helpers'

export const useHookTable = () => {
  const { t } = useTranslation()
  const [industries, setIndustry] = useState<IIndustry[]>()
  const [levelSkills, setLevelSkill] = useState<ILevelSkill[]>()
  const [rangeExperiences, setRangeExperience] = useState<IRangeExperience[]>()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.SKILL_READ,
      R.strings.skill,
    )
  }, [t])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralSkillManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetSkillManagement = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, status }

      const response: IResponseGetSkillManagement =
        await requestGetSkillManagement(params)

      if (
        isEqual(
          response?.msgcode,
          SkillMessageCode.SkillManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        const metadata = response?.data?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          return {
            key: index + 1,
            ordinalNumber: startIndex + index + 1,
            name: convertHtmlEntitiesToString(item?.name, '-'),
            industry: convertHtmlEntitiesToString(item?.industry.name, '-'),
            _id: item?._id ?? '-',
            status: convertStatusToFilterStatus(item?.status),
          }
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralSkillManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const getAllIndustry = async () => {
    try {
      const res: ResponseType<IIndustry[]> =
        await requestGetAllIndustryManagement()

      if (
        isEqual(
          res?.msgcode,
          IndustryMessageCode.IndustryManagement.successGetData,
        )
      ) {
        const data: IIndustry[] = res?.data || []
        setIndustry(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllLevelSkill = async () => {
    try {
      const res: ResponseType<ILevelSkill[]> =
        await requestGetAllLevelSkillManagement()

      if (
        isEqual(
          res?.msgcode,
          LevelSkillMessageCode.LevelSkillManagement.successGetData,
        )
      ) {
        const data: ILevelSkill[] = res?.data || []
        setLevelSkill(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  const getAllRangeExperience = async () => {
    try {
      const res: ResponseType<IRangeExperience[]> =
        await requestGetAllRangeExperienceManagement()

      if (
        isEqual(
          res?.msgcode,
          RangeExperienceMessageCode.RangeExperienceManagement.successGetData,
        )
      ) {
        const data: IRangeExperience[] = res?.data || []
        setRangeExperience(data)
      }
    } catch (error) {
      // console.log({ error })
    }
  }

  useEffect(() => {
    getAllIndustry()
    getAllLevelSkill()
    getAllRangeExperience()
  }, [])

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralSkillManagementTable>({
        title: t(R.strings.ordinal_number),
        fixed: 'left',
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
      }),
      convertColumnTable<IGeneralSkillManagementTable>({
        title: t(R.strings.skill_content_name),
        fixed: 'left',
        key: 'name',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.name} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralSkillManagementTable>({
        title: t(R.strings.skill_content_industry),
        key: 'industry',
        render: (_, record) => (
          <RenderValueTable value={record.industry} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralSkillManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                <Update id={record?._id} fetchTable={fetchNewData} />
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <Delete
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    industries,
    levelSkills,
    rangeExperiences,
  }
}
