import {
  requestExportVoucherCode,
  requestGetVoucherCode,
} from 'app/api/marketing/discount-voucher'
import { DiscountVoucherMessageCode } from 'app/api/marketing/discount-voucher/constant'
import {
  IDiscountVoucher,
  IParamGetVoucherCodeList,
} from 'app/api/marketing/discount-voucher/model'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import {
  PADDING,
  Pagination,
  ResponseType,
  formatMoney,
  initialPagination,
} from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { DefineUserDataByVoucherEnum } from './constant'
import { IDataTable } from './type'

export const useHookTable = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const [usersSelected, setServicesSelected] = useState<IDataTable[]>([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState(initialPagination)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const voucherItem: IDiscountVoucher = useMemo(() => {
    return location?.state?.item
  }, [location])

  const data = useMemo(() => {
    return usersSelected?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [usersSelected])

  const getData = async () => {
    if (!voucherItem?._id) return
    try {
      setIsLoading(true)

      const res: ResponseType<IDataTable[]> = await requestGetVoucherCode({
        voucherTemplateId: voucherItem?._id,
        keyword: search,
        page: pagination.current,
        pageSize: pagination.pageSize,
        pagesize: pagination.pageSize,
      })

      if (
        isEqual(
          res?.msgcode,
          DiscountVoucherMessageCode.DiscountVoucher.success,
        )
      ) {
        setServicesSelected(res?.data)
        setTotal(res?.metadata?.totalItem ?? 0)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [search, pagination, voucherItem?._id])

  const exportVoucherCode = async () => {
    setIsLoading(true)
    try {
      const params: IParamGetVoucherCodeList = {
        voucherTemplateId: voucherItem?._id,
      }

      const res: ResponseType<{ url?: string; Url?: string }> =
        await requestExportVoucherCode(params)

      if (res?.msgcode === DiscountVoucherMessageCode.DiscountVoucher.success) {
        window.open(res?.data?.url ?? res?.data?.Url, '_blank')
        notificationController.success({
          message: t(R.strings.download_excel_success),
        })
      } else {
        notificationController.success({
          message: t(R.strings.export_excel),
          description: t(R.strings.success),
        })
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable({
        title: '#',
        key: 'key',
        fixed: 'left',
        width: '5%',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record: IDataTable) => {
          return (
            <BaseText
              children={record?.key}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'code',
        fixed: 'left',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.code}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_use),
        key: 'used',
        render: (_, record) => {
          return (
            <BaseText
              children={`${formatMoney(record?.used ?? '0', '')}/${formatMoney(
                record?.maxUsage ?? '0',
                '',
              )}`}
              fontWeight="medium"
              opacity="0.7"
              colorText={
                isEqual(record?.used, record?.maxUsage)
                  ? 'statesGreenColor'
                  : 'primaryColor'
              }
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.number_of_users),
        key: 'infos',
        render: (_, record) => {
          return (
            <BaseText
              children={`${formatMoney(record?.infos?.length ?? 0, '')}`}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.status),
        key: 'statusUsed',
        render: (_, record) => {
          if (record?.statusUsed === undefined) return
          const define = DefineUserDataByVoucherEnum[record?.statusUsed]
          return (
            <BaseStatusWrapper $backgroundImage={define.background}>
              <BaseText
                children={t(define?.key18n)}
                fontWeight="medium"
                colorText={define?.colorText}
              />
            </BaseStatusWrapper>
          )
        },
      }),
    ]
  }, [t, usersSelected, expandedRowKeys])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    data,
    columns,
    loading,
    pagination,
    setPagination,
    total,
    search,
    onChangeSearch,
    handleTableChange,
    exportVoucherCode,
    expandedRowKeys,
    handleExpand,
  }
}

const BaseStatusWrapper = styled.div<{
  $backgroundImage: string
}>`
  padding: 5px 10px;
  border-radius: ${PADDING.xl};
  background-image: ${props => props.$backgroundImage};
  width: fit-content;
`
