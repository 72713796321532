// Message code define
export const HrMessageCode = {
  Unit: {
    successGetData: '20001',
  },
}

// Endpoint
export const HrEndPoint = {
  Unit: {
    requestGetData: 'hr/v1/unit/find',
  },
}
