import { ApiClient } from 'app/service/api-service'
import { AccountingEndPoint } from './constant'
import {
  IParamsAccounting,
  IParamsGetCostPlan,
} from './model'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import prettyBytes from 'pretty-bytes'
import R from 'app/assets/R'
import axios from 'axios'

export const requestGetCostPlan = (params: IParamsGetCostPlan) =>
  ApiClient.Get({
    url: AccountingEndPoint.CostPlan.requestGetData,
    params: { params },
  })

export const requestImportCostPlan = (body: FormData) =>
  ApiClient.Post({
    url: AccountingEndPoint.CostPlan.requestImportFile,
    body,
  })

export const requestPatientDebtContract = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.PatientDebtContract.requestGetData,
    params: { params },
    body,
  })

export const requestExportPatientDebtContract = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.PatientDebtContract.ExportPatientDebt,
    params: { params },
    body,
  })

export const requestBracesTray = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.BracesTray.requestGetData,
    params: { params },
  })

export const requestExportBracesTray = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.BracesTray.ExportBracesTray,
    params: { params },
  })

export const requestDailyInvoice = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.DailyInvoice.requestGetData,
    params: { params },
    body,
  })

export const requestExportDailyInvoice = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.DailyInvoice.Export,
    params: { params },
    body,
  })

export const requestSyncDailyInvoice = (params: IParamsAccounting) =>
  ApiClient.Get({
    url: AccountingEndPoint.DailyInvoice.sync,
    params: { params },
  })

export const requestPatientScheduleWithoutPaid = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.PatientScheduleWithoutPaid.requestGetData,
    params: { params },
    body,
  })

export const requestPatientDebt = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.PatientDebt.requestGetData,
    params: { params },
    body,
  })

export const requestExportPatientDebt = (
  params: IParamsAccounting,
  body: { treatments: string },
) =>
  ApiClient.PostWithParams({
    url: AccountingEndPoint.PatientDebt.Export,
    params: { params },
    body,
  })


export  const handleFileDownload = async (fileUrl: string, t, title: string, key: string) => {
  try {
    
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

    downloadNotificationController.downloading({
      duration: 0,
      key: key + fileUrl,
      message: `${title} ${fileName}`,
      description: `${t(R.strings.downloading)} ${0}%`,
      cancel: t(R.strings.cancel),
    })
  
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        )
        downloadNotificationController.downloading({
          duration: 0,
          key: key + fileUrl,
          message: `${title} ${fileName}`,
          description: `${t(R.strings.downloading)} ${progress}%`,
          cancel: t(R.strings.cancel),
        })
      },
    })
    // Create a temporary URL object for download

    downloadNotificationController.downloaded({
      duration: 2,
      key: key + fileUrl,
      message: `${title} ${fileName}`,
      description: prettyBytes(response?.data?.size || 0),
      cancel: t(R.strings.cancel),
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Revoke the object URL after download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('File download failed:', error)
    downloadNotificationController.downloadErrored({
      duration: 2,
      key: key + fileUrl,
      message: title,
      description: t(R.strings.download_errored),
      cancel: t(R.strings.cancel),
    })
  }
}
