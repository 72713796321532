/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestImportCostPlan } from 'app/api/accounting'
import { AccountingMessageCode } from 'app/api/accounting/constant'
import { IGeneralValueCostPlan } from 'app/api/accounting/model'
import R from 'app/assets/R'
import { PeriodType } from 'parkway-web-common'
import {
  ExpandedTableValue,
  RenderValueTableReport,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IOnCell,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { convertToFormDateImport } from 'app/service/general/service'
import { isEqual } from 'lodash'
import { Pagination, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataCostPlanMonthly } from './Monthly/data'
import { IDataCostPlanWeekly } from './Quarter/data'
import { IGeneralCostPlanTable, initialPagination } from './common-model'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
  typeHook,
  handleExpand,
  fetch,
  currentFilter,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  typeHook: PeriodType | "quarter"
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickImport = async (file: any, periodType: PeriodType | "quarter") => {
    fetchLoading(true)
    try {
      const response: ResponseType<{ countSuccess?: number }> =
        await requestImportCostPlan(
          convertToFormDateImport({
            file,
            periodtype: periodType,
            importtype: 'full',
          }),
        )

     // console.log({ response })

      if (
        isEqual(response.msgcode, AccountingMessageCode.CostPlan.successImport)
      ) {
        fetch(initialPagination, currentFilter)
        notificationController.success({
          message: t(R.strings.import_file_successful),
        })
        fetchLoading(false)
        return
      }
      fetchLoading(false)
      notificationController.error({ message: t(R.strings.import_file_error) })
    } catch (error) {
     // console.log({ error })

      fetchLoading(false)
    }
  }

  const onCellClinic = (record): IOnCell => {
    if (record?.isTotalTable && expandedRowKeys.length) return { colSpan: 2 }

    if (record?.isTotalTable && !expandedRowKeys.length) return { colSpan: 1 }

    const isExpanded = expandedRowKeys.includes(record?.key ?? 0)
    if (!isExpanded && record?.children && expandedRowKeys?.length) {
      return { rowSpan: 1, colSpan: 2 }
    }

    if (!isExpanded && record?.children) {
      return { rowSpan: 1 }
    }

    if (isExpanded && !record?.children?.length) {
      return { rowSpan: 1 }
    }

    return {
      rowSpan: record.children?.length ? record.children?.length + 1 : 0,
    }
  }

  const onCellWeek = (record): IOnCell => {
    if (record?.isTotalTable) {
      return { colSpan: 0 }
    }
    const isExpanded = expandedRowKeys.includes(record.key ?? 0)
    if (!isExpanded && record?.children && !!expandedRowKeys?.length) {
      return { colSpan: 0 }
    }
    return {}
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataCostPlanWeekly | IDataCostPlanMonthly
    let options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.clinic),
        key: 'clinic',
        onCell: onCellClinic,
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          if (record?.isTotalTable) {
            return <ExpandedTableValue value={record?.clinic} isTotalTable />
          }

          if (!record?.children) return null

          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              value={record?.clinic}
            />
          )
        },
      }),
    ]

    if (expandedRowKeys?.length && typeHook == 'quarter') {
      options = options?.concat([
        convertColumnTable<IDataCostPlanWeekly>({
          title: t(R.strings.quarter),
          key: 'quarter',
          fixed: 'left',
          onCell: onCellWeek,
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record.quarter}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    } else if (expandedRowKeys?.length && typeHook == 'month') {
      options = options?.concat([
        convertColumnTable<IDataCostPlanMonthly>({
          title: t(R.strings.month),
          key: 'month',
          fixed: 'left',
          onCell: onCellWeek,
          render: (_, record) => {
            return (
              <RenderValueTableReport
                record={record}
                valueString={record.month}
                expandedRowKeys={expandedRowKeys}
                justify="start"
              />
            )
          },
        }),
      ])
    }

    return [
      ...options,
      {
        title: t(R.strings.cost_of_goods_sold),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.cp_nvl_live),
            key: 'cpNvlLive',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.cpNvlLive ?? 0,
                secondLevelValue: record?.cpNvlLiveTotal ?? 0,
                isFillBlueInTotal: true,
                expandedRowKeys,
              })
            },
          }),
          {
            title: t(R.strings.salary_expenses_allowances),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.doctor_department),
                key: 'doctorDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.doctorDepartment ?? 0,
                    secondLevelValue: record?.doctorDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.nursing_department),
                key: 'nursingDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.nursingDepartment ?? 0,
                    secondLevelValue: record?.nursingDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.advisory_department),
                key: 'advisoryDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.advisoryDepartment ?? 0,
                    secondLevelValue: record?.advisoryDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.x_ray_technical_department),
                key: 'xRayTechnicalDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.xRayTechnicalDepartment ?? 0,
                    secondLevelValue: record?.xRayTechnicalDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.reception_department),
                key: 'receptionDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.receptionDepartment ?? 0,
                    secondLevelValue: record?.receptionDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.security_and_housekeeping_department),
                key: 'securityAndHousekeepingDepartment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.securityAndHousekeepingDepartment ?? 0,
                    secondLevelValue:
                      record?.securityAndHousekeepingDepartmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
            ],
          },
          {
            title: t(R.strings.depreciation_expenses),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.initial_construction),
                key: 'initialConstruction',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.initialConstruction ?? 0,
                    secondLevelValue: record?.initialConstructionTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.machinery_and_equipment),
                key: 'machineryAndEquipment',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.machineryAndEquipment ?? 0,
                    secondLevelValue: record?.machineryAndEquipmentTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.ccdc),
                key: 'ccdc',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.ccdc ?? 0,
                    secondLevelValue: record?.ccdcTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
            ],
          },
          {
            title: t(R.strings.location_rental_expenses),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.rental_expenses),
                key: 'rentalExpenses',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.rentalExpenses ?? 0,
                    secondLevelValue: record?.rentalExpensesTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.location_rental_tax_expenses),
                key: 'locationRentalTaxExpenses',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.locationRentalTaxExpenses ?? 0,
                    secondLevelValue: record?.locationRentalTaxExpensesTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
            ],
          },
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_operating_expenses),
            key: 'otherOperatingExpenses',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherOperatingExpenses ?? 0,
                secondLevelValue: record?.otherOperatingExpensesTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.selling_expenses),
        children: [
          {
            title: t(R.strings.marketing),
            children: [
              convertColumnTable<TypeColumn>({
                title: t(R.strings.performance),
                key: 'performance',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.performance ?? 0,
                    secondLevelValue: record?.performanceTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
              convertColumnTable<TypeColumn>({
                title: t(R.strings.branch),
                key: 'branch',
                render: (_, record) => {
                  return RenderValueTableReport({
                    record,
                    firstLevelValue: record?.branch ?? 0,
                    secondLevelValue: record?.branchTotal ?? 0,
                    expandedRowKeys,
                  })
                },
              }),
            ],
          },
          convertColumnTable<TypeColumn>({
            title: t(R.strings.call_center),
            key: 'callCenter',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.callCenter ?? 0,
                secondLevelValue: record?.callCenterTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.card_swipe_installment_fees),
            key: 'cardSwipeInstallmentFees',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.cardSwipeInstallmentFees ?? 0,
                secondLevelValue: record?.cardSwipeInstallmentFeesTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.business_management_expenses),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.marketing_bp),
            key: 'marketingBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.marketingBP ?? 0,
                secondLevelValue: record?.marketingBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.operation_bp),
            key: 'operationBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.operationBP ?? 0,
                secondLevelValue: record?.operationBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.training_bp),
            key: 'trainingBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.trainingBP ?? 0,
                secondLevelValue: record?.trainingBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.hr_bp),
            key: 'hrBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.hrBP ?? 0,
                secondLevelValue: record?.hrBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.accounting_bp),
            key: 'accountingBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.accountingBP ?? 0,
                secondLevelValue: record?.accountingBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.purchasing_bp),
            key: 'purchasingBP',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.purchasingBP ?? 0,
                secondLevelValue: record?.purchasingBPTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_expenses),
            key: 'otherExpenses',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherExpenses ?? 0,
                secondLevelValue: record?.otherExpensesTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
        ],
      },
      {
        title: t(R.strings.other_profits),
        children: [
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_income),
            key: 'otherIncome',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherIncome ?? 0,
                secondLevelValue: record?.otherIncomeTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.interest_on_deposits),
            key: 'interestOnDeposits',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.interestOnDeposits ?? 0,
                secondLevelValue: record?.interestOnDepositsTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
          convertColumnTable<TypeColumn>({
            title: t(R.strings.other_profits),
            key: 'otherProfits',
            render: (_, record) => {
              return RenderValueTableReport({
                record,
                firstLevelValue: record?.otherProfits ?? 0,
                secondLevelValue: record?.otherProfitsTotal ?? 0,
                expandedRowKeys,
              })
            },
          }),
        ]
      }
    ]
  }, [t, expandedRowKeys, typeHook])

  return {
    onClickImport,
    columns,
  }
}

export const convertGeneralCostPlanValue = (item: IGeneralValueCostPlan) => {
  return {
    childs: {
      cpNvlLive: item?.costNVLTrucTiep,
      doctorDepartment: item?.costSalaryDoctor,
      nursingDepartment: item?.costSalaryNurse,
      advisoryDepartment: item?.costSalaryConsultant,
      xRayTechnicalDepartment: item?.costSalaryTechnician,
      receptionDepartment: item?.costReceptionist,
      securityAndHousekeepingDepartment: item?.costJanitorSecurityGuard, // change
      initialConstruction: item?.costInitConstruction,
      machineryAndEquipment: item?.costOfDevice,
      rentalExpenses: item?.costOfRental,
      locationRentalTaxExpenses: item?.costOfRentalHouse,
      otherOperatingExpenses: item?.costOtherOperation,
      cardSwipeInstallmentFees: item?.costOfPayment,
      othersCosmetics: item?.revenueKhac,
      planNumber: item?.costOfRentalHouse,
      performance: item?.costPerformanceMarketing,
      callCenter: item?.costOfCallCenter,
      ccdc: item?.costOfTool,
      branch: item?.costBrandMarketing,

      marketingBP: item?.costOfMarketingBP,
      operationBP: item?.costOfOperationsBP,
      trainingBP: item?.costOfTrainingBP,
      hrBP: item?.costOfHumanResourcesBP,
      accountingBP: item?.costOfAccountingBP,
      purchasingBP: item?.costOfPurchasingBP,
      otherExpenses: item?.costOfOtherExpensesBP,
      otherIncome: item?.otherIncome,
      interestOnDeposits: item?.interestOnDeposits,
      otherProfits: item?.otherProfits,      
    },
    total: {
      cpNvlLiveTotal: item?.costNVLTrucTiep,
      doctorDepartmentTotal: item?.costSalaryDoctor,
      nursingDepartmentTotal: item?.costSalaryNurse,
      advisoryDepartmentTotal: item?.costSalaryConsultant,
      xRayTechnicalDepartmentTotal: item?.costSalaryTechnician,
      receptionDepartmentTotal: item?.costReceptionist, // change
      securityAndHousekeepingDepartmentTotal: item?.costJanitorSecurityGuard, // change
      initialConstructionTotal: item?.costInitConstruction,
      machineryAndEquipmentTotal: item?.costOfDevice,
      rentalExpensesTotal: item?.costOfRental,
      locationRentalTaxExpensesTotal: item?.costOfRentalHouse,
      otherOperatingExpensesTotal: item?.costOtherOperation,
      cardSwipeInstallmentFeesTotal: item?.costOfPayment,
      othersCosmeticsTotal: item?.revenueKhac,
      planNumberTotal: item?.costOfRentalHouse,
      performanceTotal: item?.costPerformanceMarketing,
      callCenterTotal: item?.costOfCallCenter,
      ccdcTotal: item?.costOfTool,
      branchTotal: item?.costBrandMarketing,

      marketingBPTotal: item?.costOfMarketingBP,
      operationBPTotal: item?.costOfOperationsBP,
      trainingBPTotal: item?.costOfTrainingBP,
      hrBPTotal: item?.costOfHumanResourcesBP,
      accountingBPTotal: item?.costOfAccountingBP,
      purchasingBPTotal: item?.costOfPurchasingBP,
      otherExpensesTotal: item?.costOfOtherExpensesBP,
      otherIncomeTotal: item?.otherIncome,
      interestOnDepositsTotal: item?.interestOnDeposits,
      otherProfitsTotal: item?.otherProfits,
    },
  } as {
    childs: IGeneralCostPlanTable<any>,
    total: IGeneralCostPlanTable<any>,
  }
}
