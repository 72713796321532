import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'

import { requestExportExcelReport22 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { IParamsGetReport22, IReport022 } from 'app/api/report/model/report-22'
import { getDetailPatientUrl } from 'app/common/helpers'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetFilterReport22 } from 'app/react-query/hook/report'
import { isEqual } from 'lodash'
import {
  DateUtil,
  Pagination,
  formatMoney,
  initialPagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initialPagination)

  const [filter, setFilter] = useState<IParamsGetReport22>({})

  const { flattenDataList } = usePagination()

  const filterDebounce = useDebounce<IParamsGetReport22>(filter, 500)

  const referer = filterDebounce?.referer
    ? { referer: filterDebounce?.referer }
    : {}
  const { data: dataInfinity, isLoading } = useGetFilterReport22({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    startDate: filterDebounce?.startDate,
    endDate: filterDebounce?.endDate,
    ...referer,
  })

  const dataFlatten = useMemo(() => {
    return flattenDataList(dataInfinity)
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index,
      } as IDataTable
    })
  }, [dataFlatten])

  const handleExportExcel = async () => {
    try {
      if (!filterDebounce?.startDate || !filterDebounce?.endDate) {
        notificationController?.error({
          message: t(R.strings.error),
          description: t(R.strings.select_range_date_required),
        })
        return
      }

      const response = await requestExportExcelReport22({
        startDate: filterDebounce?.startDate || '',
        endDate: filterDebounce?.endDate || '',
      })

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report22.successExportData)
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.date),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={
                record?.appliedAt
                  ? DateUtil.formatDate(record?.appliedAt, 'DD/MM/YYYY')
                  : '-'
              }
              fontWeight="medium"
              opacity="0.9"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.customer_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport022) => {
          return (
            <RenderValueTable
              value={record?.referralTo?.name ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?.referralTo?.url)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.referral_to_code),
        key: 'referral_to_code',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.referralTo?.code}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.clinic),
        key: 'clinic_to',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.referralTo?.defaultLocationName}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.name_employee),
        key: 'name_employee',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.referralFrom?.name}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.employee_id),
        key: 'employee_id',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.referralFrom?.code}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.referral_source),
        key: 'referral_source',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.referralTo?.referer}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.voucher_code),
        key: 'voucher_code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={record?.code}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),

      convertColumnTable<IDataTable>({
        title: t(R.strings.package),
        key: 'package',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <>
              {record?.treatments?.map(item => (
                <BaseText
                  children={item?.name}
                  fontWeight="medium"
                  opacity="0.5"
                  textAlign="left"
                  style={{ marginBottom: 4 }}
                />
              ))}
            </>
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.package_amount),
        key: 'package_amount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={formatMoney(record?.totalTreatmentPrice || '', 'đ')}
              fontWeight="medium"
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.payment_paid),
        key: 'payment_paid',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record: IReport022) => {
          return (
            <BaseText
              children={formatMoney(record?.payment || '', 'đ')}
              fontWeight="medium"
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport22) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
  }
}
