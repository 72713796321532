import R from 'app/assets/R'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, initialPagination, moment } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGeneralProviderManagementTable } from './type'

import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { convertArrayStatusFilterToArrayStatusNumber } from 'app/common/helpers'
import { Update } from './components/Update'
import { Delete } from './components/Delete'
import { ValueTableWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { BaseCol } from 'app/components/common/BaseCol'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import {
  IParamsGetProviderManagement,
  IResponseGetProviderManagement,
} from 'app/api/inventory/model/management'
import {
  requestExportProviderManagement,
  requestGetProviderManagement,
} from 'app/api/inventory'
import { MessageCode } from 'app/api/inventory/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { IMAGE_ACTION } from 'app/common/config'
import { DATE } from 'app/common/constants'
import dayjs from 'dayjs'

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.INVENTORY,
      FunctionPermissionEnum.PROVIDER_READ,
      R.strings.role,
    )
  }, [])

  const onClickExport = async params => {
    try {
      const response = await requestExportProviderManagement({
        keyword: params?.search ?? filter?.search,
      })
      const url = `${IMAGE_ACTION.IMAGE_SERVER}/${response?.data?.Url}`
      window.open(url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
  }
  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralProviderManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetProviderManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      params = { ...params, ...newFilter, status }

      const response: IResponseGetProviderManagement =
        await requestGetProviderManagement(params)

      if (isEqual(response?.msgcode, MessageCode.successGetData)) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const transformData = data => {
          if (isEmpty(data)) {
            return data
          }

          return data?.map((item, index) => {
            return {
              key: `${item?._id}-${index + 1}`,
              ordinalNumber: startIndex + index + 1,
              contact: item?.contact ?? '-',
              name: item?.name ?? '-',
              bankinfo: item?.bankinfo ?? '-',
              TIN: item?.TIN ?? '-',
              code: item?.code ?? '-',
              date: item?.date,
              _id: item?._id ?? '-',
            }
          })
        }

        const dataTables = transformData(data)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralProviderManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleTableChange = pagination => {
    fetch(pagination, filter)
  }

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.code),
        key: 'code',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.code} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.name),
        key: 'name',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.name} type="Main" />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.contact),
        key: 'contact',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record?.contact} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.TIN),
        key: 'TIN',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.TIN} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.payment_date),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={
              record?.date ? moment(record?.date).format(DATE.DD_MM_YYYY) : ''
            }
            type="Base"
          />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: t(R.strings.bankinfo),
        key: 'bankinfo',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.bankinfo} type="Base" />
        ),
      }),

      convertColumnTable<IGeneralProviderManagementTable>({
        title: '',
        key: 'action',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <ValueTableWrapper
            justify={'end'}
            align={'middle'}
            gutter={[10, 10]}
            wrap={false}
          >
            <BaseCol>
              <ActionsDropdown
                actionComponent={
                  <>
                    <Update
                      provider={{ ...record, date: dayjs(record?.date) }}
                      fetchTable={fetchNewData}
                    />
                    <Delete
                      id={record?._id}
                      fetchTable={fetchNewData}
                      name={record?.name}
                    />
                  </>
                }
              />
            </BaseCol>
          </ValueTableWrapper>
        ),
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    onClickExport,
  }
}
