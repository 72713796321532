import { IUserDataVoucher } from 'app/api/marketing/discount-voucher/model'
import { BaseTable } from 'app/components/common/BaseTable'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  DateUtil,
  FONT_SIZE,
  FONT_WEIGHT,
  FORMAT_ONLY_YEAR,
  formatMoney,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IDataTable } from './type'
import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { getDetailPatientUrl } from 'app/common/helpers'

export const PatientTable = ({ users }: { users: IUserDataVoucher[] }) => {
  const { t } = useTranslation()

  const data: IDataTable[] = useMemo(() => {
    return users.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [users])

  const columns = useMemo(() => {
    return [
      convertColumnTable<IDataTable>({
        key: 'name',
        title: t(R.strings.patient_name),
        classNameWidthColumnOverwrite: 'big-column',
        render: (value: string) => {
          return <BaseText children={value} fontWeight="medium" />
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'id',
        title: t(R.strings.patient_id),
        render: (_, record) => {
          return (
            <RenderValueTable
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record._id)}
              value={record.id}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'used',
        title: t(R.strings.number_of_use),
        render: (value: number) => {
          return (
            <BaseText
              children={formatMoney(value ?? 0, '')}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'dob',
        title: t(R.strings.birthday),
        render: (value?: string) => {
          return (
            <BaseText
              children={DateUtil.formatDate(value ?? '', FORMAT_ONLY_YEAR)}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'gender',
        title: t(R.strings.gender),
        render: (value?: string) => {
          return (
            <BaseText
              children={value ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        key: 'email',
        title: t(R.strings.email),
        render: (value?: string) => {
          return (
            <BaseText
              children={value ?? '-'}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
    ]
  }, [])

  return (
    <TableWrapper
      columns={columns}
      dataSource={data}
      pagination={{
        total: data.length,
        pageSize: 10,
        showSizeChanger: false,
      }}
      scroll={{
        x: true,
      }}
    />
  )
}

const TableWrapper = styled(BaseTable)`
  table > thead > tr:first-child > *:first-child {
    border-radius: 0px !important;
  }
  table > thead > tr:last-child > *:last-child {
    border-radius: 0px !important;
  }
  .ant-table-thead > tr > th {
    border-right: 0px !important;
    border-left: 0px !important;
    background: #ebeef2;
    padding: 8px 16px;
    color: ${convertedVariables.primaryColor};
    opacity: 0.7;
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.xxs};
  }
  .ant-table {
    margin-inline: -16px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    width: 72px !important;
  }

  .class-left {
    .ant-row {
      display: flex;
      justify-content: flex-end;
    }
    text-align: right !important;
  }
`
