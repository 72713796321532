/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import {
  IParamsGetReport21,
  IReferralSource,
} from 'app/api/report/model/report-21'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { usePagination } from 'app/hook'
import { useGetReferralSource } from 'app/react-query/hook/report'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT, ResponseType } from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: IParamsGetReport21) => void
  formData?: IParamsGetReport21
}

export const FilterLayout: React.FC<IProps> = ({ formData, onChange }) => {
  const { flattenDataList } = usePagination()

  const { data: dataReferralSource, isLoading: isLoadingReferralSource } =
    useGetReferralSource()

  const referralSourceList = useMemo(() => {
    const res: ResponseType<IReferralSource[]> =
      flattenDataList(dataReferralSource)
    return res?.data
  }, [dataReferralSource])

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeReferralSource = (id: string) => {
    onChange && onChange({ ...formData, referer: id })
  }

  const onChangeStartDate = (day?: Dayjs) => {
    if (day && formData?.endDate && day.isAfter(formData.endDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, startDate: value })
  }

  const onChangeEndDate = (day?: Dayjs) => {
    if (day && formData?.startDate && day.isBefore(formData.startDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, endDate: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 8]} align={'middle'}>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_keyword_search)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.start_date)}
              $prefixWidth={150}
              picker="date"
              value={
                formData?.startDate ? Dates.getDate(formData?.startDate) : null
              }
              onChange={day => onChangeStartDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
              showTime
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={8}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.end_date)}
              $prefixWidth={160}
              value={
                formData?.endDate ? Dates.getDate(formData?.endDate) : null
              }
              picker="date"
              onChange={day => onChangeEndDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY'}
              placeholder={t(R.strings.all)}
              showTime
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseFormItem hiddenLabel required>
            <BaseSelectWrapper
              $prefix={t(R.strings.referral_source)}
              $prefixWidth={170}
              showSearch
              placeholder={t(R.strings.all)}
              allowClear
              onChange={id => onChangeReferralSource?.(id as string)}
              options={referralSourceList?.map(item => ({
                value: item.key,
                label: item.value,
              }))}
              loading={isLoadingReferralSource}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``
const Picker = styled(BaseDatePicker)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;

  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 40}px;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
  .ant-select-selection-search {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
