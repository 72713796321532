import { requestGetRolesManagement } from 'app/api/roles'
import { RoleMessageCode } from 'app/api/roles/constant'
import {
  IParamsGetRoleManagement,
  IResponseGetRoleManagement,
} from 'app/api/roles/models/role'
import R from 'app/assets/R'
import { BaseChip } from 'app/components/common/BaseChip'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  StatusTableType,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteRole } from './components/DeleteRole'
import { DetailPermission } from './components/DetailPermission'
import { UpdateRole } from './components/UpdateRole'
import { IGeneralRoleManagementTable } from './type'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'

const convertStatusNumberToStatusEnum = (status?: number) => {
  switch (status) {
    case 1:
      return StatusTableEnum.active
    case 2:
      return StatusTableEnum.inactive
    case 4:
      return StatusTableEnum.deleted
    default:
      return StatusTableEnum.inactive
  }
}

const convertStatusEnumToStatusNumber = (statusList?: StatusTableType[]) => {
  let listStatus: number[] = []
  statusList?.forEach(status => {
    switch (status) {
      case StatusTableEnum.active:
        listStatus = listStatus.concat([1])
        break
      case StatusTableEnum.inactive:
        listStatus = listStatus.concat([2])
        break
      case StatusTableEnum.deleted:
        listStatus = listStatus.concat([4])
        break
      default:
        return undefined
    }
  })
  return listStatus
}

export const useHookTable = () => {
  const { t } = useTranslation()
  const {
    isHavePermissionFunction,
    isHavePermissionFunctionAndGoBack,
  } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.ROLES_READ,
      R.strings.role,
    )
  }, [])

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralRoleManagementTable>
    try {
      const params: IParamsGetRoleManagement = {
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
        status: convertStatusEnumToStatusNumber(filter?.status ?? []),
      }

      const response: IResponseGetRoleManagement =
        await requestGetRolesManagement(params)

      if (
        isEqual(
          response?.msgcode,
          RoleMessageCode.RoleManagement.successGetData,
        )
      ) {
        const data = response?.data
        const metadata = response?.metadata

        const dataTable: IGeneralRoleManagementTable[] = data?.map(
          (role, index) => {
            return {
              name: role.name,
              permission: role.permissions,
              key: index + 1,
              status: convertStatusNumberToStatusEnum(role.status),
              ordinalNumber: index + 1,
              role,
            }
          },
        )

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTable,
        } as IDataBaseTable<IGeneralRoleManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination, filter)
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralRoleManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable value={record.ordinalNumber} type="Number" />
        ),
        width: '5%',
      }),
      convertColumnTable<IGeneralRoleManagementTable>({
        title: t(R.strings.role_content_name_role),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.name} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralRoleManagementTable>({
        title: t(R.strings.role_content_permission),
        key: 'permission',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          const permissionCount = record?.permission?.length ?? 0
          return (
            <BaseRow gutter={[15, 15]}>
              {record?.permission?.slice(0, 4)?.map((per, idx) => {
                return (
                  <BaseCol key={idx}>
                    <BaseChip index={idx} name={per.name} />
                  </BaseCol>
                )
              })}

              {permissionCount > 4 ? (
                <DetailPermission
                  permissionCount={permissionCount}
                  permissions={record?.permission}
                />
              ) : null}
            </BaseRow>
          )
        },
      }),
      convertColumnTable<IGeneralRoleManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'big-column',
        width: '20%',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record.status}
              type="Status"
              isNotShowThreeDot={
                !isHavePermissionFunction(
                  ModulePermissionEnum.HR,
                  FunctionPermissionEnum.ROLES_UPDATE,
                ) &&
                !isHavePermissionFunction(
                  ModulePermissionEnum.HR,
                  FunctionPermissionEnum.ROLES_DELETE,
                )
              }
              actionComponent={
                <>
                  {isHavePermissionFunction(
                    ModulePermissionEnum.HR,
                    FunctionPermissionEnum.ROLES_UPDATE,
                  ) ? (
                    <UpdateRole fetchTable={fetchNewData} role={record?.role} />
                  ) : null}

                  {!isEqual(record?.status, StatusTableEnum.deleted) &&
                    isHavePermissionFunction(
                      ModulePermissionEnum.HR,
                      FunctionPermissionEnum.ROLES_DELETE,
                    ) && (
                      <DeleteRole
                        fetchTable={fetchNewData}
                        role={record?.role}
                      />
                    )}
                </>
              }
            />
          )
        },
      }),
    ]
    return option
  }, [t, fetchNewData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
    isHavePermissionFunction,
  }
}
