import R from 'app/assets/R'
import {
  IResponseFilter,
  initResponseFilterTableManagement,
} from 'app/components/tables/BaseTableManagement/FilterManagementTable/type'
import {
  RenderValueTable,
  StatusTableEnum,
  useHookTableManagement,
} from 'app/components/tables/BaseTableManagement/hook'
import {
  ExpandedTableValue,
  convertColumnTable,
} from 'app/components/tables/BaseTableReport/hook'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { isEmpty, isEqual } from 'lodash'
import { Pagination, initialPagination } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Delete } from './components/Delete'
import { IGeneralUnitManagementTable } from './type'
import {
  IParamsGetUnitManagement,
  IResponseGetUnitManagement,
} from '../../../api/unit/model/unit-management'
import { requestGetUnitManagement } from 'app/api/unit'
import { UnitMessageCode } from 'app/api/unit/constant'
import { Update } from './components/Update'
import { CreateUnitChild } from './components/CreateUnitChild'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import {
  convertArrayStatusFilterToArrayStatusNumber,
  convertStatusToFilterStatus,
} from 'app/common/helpers'

const CHILD_INDEX = 1000
const transformData = (data, startIndex = 0, isChild = false) => {
  return data?.map((item, index) => {
    const key = !isChild ? index + 1 : CHILD_INDEX * startIndex + index
    const transformedItem = {
      key: key,
      ordinalNumber: key,
      name: item?.name ?? '-',
      unitGroupName: item?.unitGroupName,
      unitTypeId: item?.unitTypeId ?? '-',
      unitTypeName: item?.unitTypeName ?? '-',
      description: item?.description ?? '-',
      code: item?.code ?? '-',
      _id: item?._id ?? '-',
      children: !isEmpty(item?.children)
        ? transformData(item?.children, startIndex + key, true)
        : [],
      status: convertStatusToFilterStatus(item?.status),
    }

    return transformedItem
  })
}

export const useHookTable = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState<IResponseFilter>(
    initResponseFilterTableManagement,
  )

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      ModulePermissionEnum.HR,
      FunctionPermissionEnum.UNIT_READ,
      R.strings.account_management,
    )
  }, [t])

  const getDataTable = async (
    pagination: Pagination,
    newFilter?: IResponseFilter,
  ) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IGeneralUnitManagementTable>
    try {
      const status = convertArrayStatusFilterToArrayStatusNumber(
        newFilter?.status ?? filter?.status,
      )
      let params: IParamsGetUnitManagement = {
        page: pagination?.current,
        pageSize: pagination?.pageSize,
        keyword: newFilter?.search ?? filter?.search,
      }

      if (status !== undefined) {
        params = { ...params, status }
      }

      const response: IResponseGetUnitManagement =
        await requestGetUnitManagement(params)

      if (
        isEqual(
          response?.msgcode,
          UnitMessageCode.UnitManagement.successGetData,
        )
      ) {
        const data = response?.data?.rows
        const metadata = response?.data?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = transformData(data, startIndex)

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IGeneralUnitManagementTable>
      }

      return initData
    } catch (error) {
      return initData
    }
  }

  const { tableData, selectRowsTable, handleTableChange, fetch, rowSelection } =
    useHookTableManagement({ getDataTable })

  const handleChangeFilter = (newFilter: IResponseFilter) => {
    if (!isEqual(newFilter, filter)) {
      setFilter(newFilter)
      fetch(
        {
          ...tableData.pagination,
          current: initialPagination.current,
        },
        newFilter,
      )
    }
  }

  const fetchNewData = () => {
    fetch(initialPagination)
    setFilter(initResponseFilterTableManagement)
  }

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    const isKeyExpanded = expandedRowKeys.includes(newKey)

    if (!isKeyExpanded) {
      // If the key is not in the array and the row is expanding, add it
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      // If the key is in the array and the row is collapsing, remove it
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.ordinal_number),
        key: 'ordinalNumber',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          if (record && record?.key && record?.key >= CHILD_INDEX) {
            return
          }

          return <RenderValueTable value={record.ordinalNumber} type="Number" />
        },
      }),
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.unit_content_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        className: 'name-column',
        render: (_, record) => {
          const notExpand = isEmpty(record.children)

          const _handleExpand = () => {
            return handleExpand(!notExpand, record?.key)
          }

          return (
            <ExpandedTableValue
              value={record.name?.toString()}
              isNotHaveActionExpanded={notExpand}
              handleExpand={_handleExpand}
              isHaveAreaWhenNotHaveActionExpanded
            />
          )
        },
      }),
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.unit_content_id),
        key: 'code',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => (
          <RenderValueTable value={record.code} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.unit_content_unitTypeId),
        key: 'unitTypeName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record.unitTypeName} type="Main" />
        },
      }),
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.unit_content_unit_department),
        key: 'unitGroupName',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return <RenderValueTable value={record.unitGroupName} type="Main" />
        },
      }),
      convertColumnTable<IGeneralUnitManagementTable>({
        title: t(R.strings.status),
        key: 'status',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.status}
            type="Status"
            actionComponent={
              <>
                <CreateUnitChild id={record?._id} fetchTable={fetchNewData} />
                <Update
                  id={record?._id}
                  fetchTable={fetchNewData}
                  listUnit={tableData?.data ?? []}
                />
                {!isEqual(record?.status, StatusTableEnum.deleted) && (
                  <Delete
                    id={record?._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                  />
                )}
              </>
            }
          />
        ),
      }),
    ]
    return option
  }, [t, fetchNewData, tableData])

  return {
    tableData,
    selectRowsTable,
    handleTableChange,
    fetchNewData,
    expandedRowKeys,
    handleExpand,
    rowSelection,
    columns,
    handleChangeFilter,
    filter,
  }
}
