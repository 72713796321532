export enum ModulePermissionEnum {
  DAY_OFF = 'dayoff',
  DAY_LEAVE = 'dayleave',
  PROFILE = 'profile',
  DASHBOARD = 'dashboard',
  ATTENDANCE = 'attendance',
  WORK_SCHEDULE = 'workschedule',
  MAIL_BOX = 'mailbox',
  REQUESTS = 'requests',
  TASK = 'tasks',
  INCOME = 'income',
  MEDICAL_EXAMINATION = 'medical-examination',
  DENTAL_TREATMENT_MANAGEMENT = 'dental-treatment-management',
  HR = 'hr',
  INVENTORY = 'inventory',
  PURCHASING = 'purchasing',
  CUSTOMER_CARE = 'customer-care',
  CLINIC_MANAGEMENT = 'clinic-management',
  REPORT = 'report',
  MARKETING = 'marketing',
  ACCOUNTING = 'accounting',

  ACCOUNT_MANAGEMENT = 'account-management',
  JOB_TITLE_MANAGEMENT = 'job-title-management',
  JOB_TITLE_LEVEL_MANAGEMENT = 'job-title-level-management',
  PROFILE_MANAGEMENT_MANAGEMENT = 'profile-management',
  UNIT_MANAGEMENT = 'unit-management',
  SKILL_MANAGEMENT = 'skill-management',
  DEGREE_MANAGEMENT = 'degree-management',

  DAY_LEAVE_POLICY = 'day-leave-policy',
  RIGHTS = 'rights',
  JOB_TITLE_ROLES = 'job-title-roles',
}

export enum FunctionPermissionEnum {
  // Rights
  RIGHTS_READ = 'RIGHTS_READ',

  // Roles features
  ROLES_CREATE = 'ROLES_CREATE',
  ROLES_READ = 'ROLES_READ',
  ROLES_UPDATE = 'ROLES_UPDATE',
  ROLES_DELETE = 'ROLES_DELETE',

  // JobTitleRoles features
  JOB_TITLE_ROLES_CREATE = 'JOB_TITLE_ROLES_CREATE',
  JOB_TITLE_ROLES_READ = 'JOB_TITLE_ROLES_READ',
  JOB_TITLE_ROLES_UPDATE = 'JOB_TITLE_ROLES_UPDATE',
  JOB_TITLE_ROLES_BLOCK = 'JOB_TITLE_ROLES_BLOCK',

  //un working day features = day off, leaves
  UN_WORKING_DAY_DAY_OFF_CREATE = 'UN_WORKING_DAY_DAY_OFF_CREATE',
  UN_WORKING_DAY_DAY_OFF_READ = 'UN_WORKING_DAY_DAY_OFF_READ',
  UN_WORKING_DAY_DAY_OFF_PROCESS = 'UN_WORKING_DAY_DAY_OFF_PROCESS',
  UN_WORKING_DAY_DAY_LEAVE_CREATE = 'UN_WORKING_DAY_DAY_LEAVE_CREATE',
  UN_WORKING_DAY_DAY_LEAVE_READ = 'UN_WORKING_DAY_DAY_LEAVE_READ',
  UN_WORKING_DAY_DAY_LEAVE_PROCESS = 'UN_WORKING_DAY_DAY_LEAVE_PROCESS',

  // un working day policy features
  UN_WORKING_DAY_POLICY_READ = 'UN_WORKING_DAY_POLICY_READ',
  UN_WORKING_DAY_POLICY_CREATE = 'UN_WORKING_DAY_POLICY_CREATE',
  UN_WORKING_DAY_POLICY_UPDATE = 'UN_WORKING_DAY_POLICY_UPDATE',
  UN_WORKING_DAY_POLICY_DELETE = 'UN_WORKING_DAY_POLICY_DELETE',

  // profile features
  PROFILE_MANAGEMENT_CREATE = 'PROFILE_CREATE',
  PROFILE_MANAGEMENT_READ = 'PROFILE_READ',
  PROFILE_MANAGEMENT_UPDATE = 'PROFILE_UPDATE',
  PROFILE_MANAGEMENT_BLOCK = 'PROFILE_BLOCK',

  // account features
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_READ = 'ACCOUNT_READ',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',

  // job-title features
  JOB_TITLE_CREATE = 'JOB_TITLE_CREATE',
  JOB_TITLE_READ = 'JOB_TITLE_READ',
  JOB_TITLE_UPDATE = 'JOB_TITLE_UPDATE',
  JOB_TITLE_DELETE = 'JOB_TITLE_DELETE',

  // job-title-level features
  JOB_TITLE_LEVEL_CREATE = 'JOB_TITLE_LEVEL_CREATE',
  JOB_TITLE_LEVEL_READ = 'JOB_TITLE_LEVEL_READ',
  JOB_TITLE_LEVEL_UPDATE = 'JOB_TITLE_LEVEL_UPDATE',
  JOB_TITLE_LEVEL_DELETE = 'JOB_TITLE_LEVEL_DELETE',

  // unit features
  UNIT_CREATE = 'UNIT_CREATE',
  UNIT_READ = 'UNIT_READ',
  UNIT_UPDATE = 'UNIT_UPDATE',
  UNIT_DELETE = 'UNIT_DELETE',

  // skill features
  SKILL_CREATE = 'SKILL_CREATE',
  SKILL_READ = 'SKILL_READ',
  SKILL_UPDATE = 'SKILL_UPDATE',
  SKILL_DELETE = 'SKILL_DELETE',

  // degree features
  DEGREE_CREATE = 'DEGREE_CREATE',
  DEGREE_READ = 'DEGREE_READ',
  DEGREE_UPDATE = 'DEGREE_UPDATE',
  DEGREE_DELETE = 'DEGREE_DELETE',

  // report features
  REPORT_READ_REPORT_1 = 'REPORT_READ_REPORT_1',
  REPORT_READ_REPORT_2 = 'REPORT_READ_REPORT_2',
  REPORT_READ_REPORT_3 = 'REPORT_READ_REPORT_3',
  REPORT_READ_REPORT_4 = 'REPORT_READ_REPORT_4',
  REPORT_READ_REPORT_5 = 'REPORT_READ_REPORT_5',
  REPORT_READ_REPORT_6 = 'REPORT_READ_REPORT_6',
  REPORT_READ_REPORT_7 = 'REPORT_READ_REPORT_7',
  REPORT_READ_REPORT_8 = 'REPORT_READ_REPORT_8',
  REPORT_READ_REPORT_9 = 'REPORT_READ_REPORT_9',
  REPORT_READ_REPORT_10 = 'REPORT_READ_REPORT_10',
  REPORT_READ_REPORT_11 = 'REPORT_READ_REPORT_11',
  REPORT_READ_REPORT_12 = 'REPORT_READ_REPORT_12',
  REPORT_READ_REPORT_13 = 'REPORT_READ_REPORT_13',
  REPORT_READ_REPORT_14 = 'REPORT_READ_REPORT_14',
  REPORT_READ_REPORT_15 = 'REPORT_READ_REPORT_15',
  REPORT_READ_REPORT_16 = 'REPORT_READ_REPORT_16',
  REPORT_READ_REPORT_17 = 'REPORT_READ_REPORT_17',
  REPORT_READ_REPORT_18 = 'REPORT_READ_REPORT_18',
  REPORT_READ_REPORT_20 = 'REPORT_READ_REPORT_20',
  REPORT_READ_REPORT_21 = 'REPORT_READ_REPORT_21',
  REPORT_READ_REPORT_22 = 'REPORT_READ_REPORT_22',

  REPORT_EXPORT_REPORT_1 = 'REPORT_EXPORT_REPORT_1',
  REPORT_EXPORT_REPORT_2 = 'REPORT_EXPORT_REPORT_2',
  REPORT_EXPORT_REPORT_3 = 'REPORT_EXPORT_REPORT_3',
  REPORT_EXPORT_REPORT_4 = 'REPORT_EXPORT_REPORT_4',
  REPORT_EXPORT_REPORT_5 = 'REPORT_EXPORT_REPORT_5',
  REPORT_EXPORT_REPORT_6 = 'REPORT_EXPORT_REPORT_6',
  REPORT_EXPORT_REPORT_7 = 'REPORT_EXPORT_REPORT_7',
  REPORT_EXPORT_REPORT_8 = 'REPORT_EXPORT_REPORT_8',
  REPORT_EXPORT_REPORT_9 = 'REPORT_EXPORT_REPORT_9',
  REPORT_EXPORT_REPORT_10 = 'REPORT_EXPORT_REPORT_10',
  REPORT_EXPORT_REPORT_11 = 'REPORT_EXPORT_REPORT_11',
  REPORT_EXPORT_REPORT_12 = 'REPORT_EXPORT_REPORT_12',
  REPORT_EXPORT_REPORT_13 = 'REPORT_EXPORT_REPORT_13',
  REPORT_EXPORT_REPORT_14 = 'REPORT_EXPORT_REPORT_14',
  REPORT_EXPORT_REPORT_17 = 'REPORT_EXPORT_REPORT_17',
  REPORT_EXPORT_REPORT_18 = 'REPORT_EXPORT_REPORT_18',
  REPORT_EXPORT_REPORT_20 = 'REPORT_EXPORT_REPORT_20',
  REPORT_EXPORT_REPORT_21 = 'REPORT_EXPORT_REPORT_21',
  REPORT_EXPORT_REPORT_22 = 'REPORT_EXPORT_REPORT_22',

  // Customer care
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  BOOKING_APPOINTMENT = 'BOOKING_APPOINTMENT',
  BOOKING_EMPLOYEE_APPOINTMENT = 'BOOKING_EMPLOYEE_APPOINTMENT',
  SEARCH_APPOINTMENT = 'SEARCH_APPOINTMENT',

  IMPORT_PLAN_CUSTOMER_CARE = 'IMPORT_PLAN_CUSTOMER_CARE',
  IMPORT_PLAN_CHECK_IN_CUSTOMER_CARE = 'IMPORT_PLAN_CHECK_IN_CUSTOMER_CARE',
  IMPORT_PLAN_CHECK_IN_BY_SERVICE_CUSTOMER_CARE = 'IMPORT_PLAN_CHECK_IN_BY_SERVICE_CUSTOMER_CARE',
  READ_REPORT_SURVEY_SERVICE_CUSTOMER_CARE = 'READ_REPORT_SURVEY_SERVICE_CUSTOMER_CARE',
  EXPORT_REPORT_SURVEY_SERVICE_CUSTOMER_CARE = 'EXPORT_REPORT_SURVEY_SERVICE_CUSTOMER_CARE',

  // marketing
  IMPORT_MARKETING_PLAN = 'IMPORT_MARKETING_PLAN',
  READ_DISCOUNT_CAMPAIGN = 'READ_DISCOUNT_CAMPAIGN',
  CREATE_DISCOUNT_CAMPAIGN = 'CREATE_DISCOUNT_CAMPAIGN',
  UPDATE_DISCOUNT_CAMPAIGN = 'UPDATE_DISCOUNT_CAMPAIGN',
  FINISH_DISCOUNT_CAMPAIGN = 'FINISH_DISCOUNT_CAMPAIGN',
  CANCEL_DISCOUNT_CAMPAIGN = 'CANCEL_DISCOUNT_CAMPAIGN',
  READ_DISCOUNT_VOUCHER = 'READ_DISCOUNT_VOUCHER',
  CREATE_DISCOUNT_VOUCHER = 'CREATE_DISCOUNT_VOUCHER',
  STOP_DISCOUNT_VOUCHER = 'STOP_DISCOUNT_VOUCHER',
  READ_LIST_VOUCHER_CODE = 'READ_LIST_VOUCHER_CODE',
  EXPORT_LIST_VOUCHER_CODE = 'EXPORT_LIST_VOUCHER_CODE',
  READ_LIMIT_DISCOUNT_MANAGEMENT = 'READ_LIMIT_DISCOUNT_MANAGEMENT',
  EXPORT_LIMIT_DISCOUNT_MANAGEMENT = 'EXPORT_LIMIT_DISCOUNT_MANAGEMENT',

  // accounting
  READ_BRACES_TRAY = 'READ_BRACES_TRAY',
  EXPORT_BRACES_TRAY = 'EXPORT_BRACES_TRAY',

  READ_DAILY_INVOICE = 'READ_DAILY_INVOICE',
  EXPORT_DAILY_INVOICE = 'EXPORT_DAILY_INVOICE',

  READ_SCHEDULE_WITHOUT_PAID = 'READ_DAILY_INVOICE',
  EXPORT_SCHEDULE_WITHOUT_PAID = 'EXPORT_DAILY_INVOICE',

  READ_ORTHODONTIC_WITHOUT_PAID = 'READ_DAILY_INVOICE',
  EXPORT_ORTHODONTIC_WITHOUT_PAID = 'EXPORT_DAILY_INVOICE',

  READ_PATIENT_OUTDATED = 'READ_DAILY_INVOICE',
  // manage-customer-debt
  READ_CUSTOMER_DEBT_MANAGEMENT = 'READ_CUSTOMER_DEBT_MANAGEMENT',
  EXPORT_CUSTOMER_DEBT_MANAGEMENT = 'EXPORT_CUSTOMER_DEBT_MANAGEMENT',

  // item management
  ITEM_CREATE = 'ITEM_CREATE',
  ITEM_READ = 'ITEM_READ',
  ITEM_UPDATE = 'ITEM_UPDATE',
  ITEM_DELETE = 'ITEM_DELETE',

  // category management
  CATEGORY_CREATE = 'CATEGORY_CREATE',
  CATEGORY_READ = 'CATEGORY_READ',
  CATEGORY_UPDATE = 'CATEGORY_UPDATE',
  CATEGORY_DELETE = 'CATEGORY_DELETE',

  DASHBOARD_READ_BOD = 'DASHBOARD_READ_BOD',
  DASHBOARD_READ_DOCTOR = 'DASHBOARD_READ_DOCTOR',
  DASHBOARD_READ_NURSE = 'DASHBOARD_READ_NURSE',
  DASHBOARD_READ_CONSULTATION = 'DASHBOARD_READ_CONSULTATION',

  // dental treatment
  PATIENT_RECORD_MANAGEMENT_READ_LIST = 'PATIENT_RECORD_MANAGEMENT_READ_LIST',
  PATIENT_RECORD_MANAGEMENT_CREATE = 'PATIENT_RECORD_MANAGEMENT_CREATE',
  PATIENT_RECORD_MANAGEMENT_UPDATE = 'PATIENT_RECORD_MANAGEMENT_UPDATE',
  PATIENT_RECORD_MANAGEMENT_DELETE = 'PATIENT_RECORD_MANAGEMENT_DELETE',
  PATIENT_RECORD_MANAGEMENT_EXPORT = 'PATIENT_RECORD_MANAGEMENT_EXPORT',
  PATIENT_RECORD_MANAGEMENT_READ_HISTORY = 'PATIENT_RECORD_MANAGEMENT_READ_HISTORY',
  PATIENT_RECORD_MANAGEMENT_CREATE_APPOINTMENT = 'PATIENT_RECORD_MANAGEMENT_CREATE_APPOINTMENT',
  READ_PATIENT_SEARCH = 'READ_PATIENT_SEARCH',
  EXPORT_PATIENT_SEARCH = 'EXPORT_PATIENT_SEARCH',

  // provider management
  PROVIDER_CREATE = 'PROVIDER_CREATE',
  PROVIDER_READ = 'PROVIDER_READ',
  PROVIDER_UPDATE = 'PROVIDER_UPDATE',
  PROVIDER_DELETE = 'PROVIDER_DELETE',
  PROVIDER_EXPORT = 'PROVIDER_EXPORT',

  // material management
  MATERIAL_CREATE = 'MATERIAL_CREATE',
  MATERIAL_READ = 'MATERIAL_READ',
  MATERIAL_UPDATE = 'MATERIAL_UPDATE',
  MATERIAL_DELETE = 'MATERIAL_DELETE',
  MATERIAL_EXPORT = 'MATERIAL_EXPORT',

  // material category management
  MATERIAL_CATEGORY_CREATE = 'MATERIAL_CATEGORY_CREATE',
  MATERIAL_CATEGORY_READ = 'MATERIAL_CATEGORY_READ',
  MATERIAL_CATEGORY_UPDATE = 'MATERIAL_CATEGORY_UPDATE',
  MATERIAL_CATEGORY_DELETE = 'MATERIAL_CATEGORY_DELETE',
  MATERIAL_CATEGORY_EXPORT = 'MATERIAL_CATEGORY_EXPORT',

  // Warehouse management
  WAREHOUSE_CREATE = 'PROVIDER_CREATE',
  WAREHOUSE_READ = 'PROVIDER_READ',
  WAREHOUSE_UPDATE = 'PROVIDER_UPDATE',
  WAREHOUSE_DELETE = 'PROVIDER_DELETE',
  WAREHOUSE_EXPORT = 'PROVIDER_EXPORT',
}
