/* eslint-disable @typescript-eslint/no-explicit-any */
import { MoreOutlined } from '@ant-design/icons'
import { requestGetItemManagement } from 'app/api/item'
import { ItemMessageCode } from 'app/api/item/constant'
import { IItemManagement } from 'app/api/item/model/management'
import R from 'app/assets/R'
import { convertStatusFilterToStatusEnum } from 'app/common/helpers'
import { ResponseTypeV2 } from 'app/common/model'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { ButtonUpdate } from 'app/components/common/ButtonUpdate'
import { ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH } from 'app/components/router/route-path'
import {
  ExpandedTableValue,
  convertColumnTable,
  useBaseTableReportHook,
} from 'app/components/tables/BaseTableReport/hook'
import {
  IBaseColumnReport,
  IDataBaseTable,
  IParamsHookReport,
} from 'app/components/tables/BaseTableReport/type'
import { randomMoney } from 'app/components/tables/common-table/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  Pagination,
  formatMoney,
  initialPagination,
} from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DefineStatusProductService,
  IDataTableItemManagement,
  TypeItemEnum,
} from './common-model'
import { IFilterResponseItemManagement } from './components/FilterItemManagement/type'
import { DeleteItem } from './components/modal/item-management/DeleteItem'

export const useTableWeeklyHook = (props: IParamsHookReport) => {
  const { t } = useTranslation()

  const getDataTable = async (pagination: Pagination, filter) => {
    const initData = {
      pagination: pagination ?? initialPagination,
      data: [],
    } as IDataBaseTable<IDataTableItemManagement>
    try {
      const params = {
        keyword: filter?.keyword ?? undefined,
        status: filter?.status
          ? convertStatusFilterToStatusEnum(filter?.status)
          : undefined,
        page: pagination?.current,
        pagesize: pagination?.pageSize,
        type: filter?.type ?? Object.keys(TypeItemEnum).map(key => key),
        categoryid: filter?.categoryid ?? undefined,
      }

      const response: ResponseTypeV2<IItemManagement[]> =
        await requestGetItemManagement(params)

      if (
        isEqual(
          response?.msgcode,
          ItemMessageCode.ItemManagement.successGetData,
        )
      ) {
        const data = response?.data
        const metadata = response?.metadata

        const startIndex =
          ((pagination?.current ?? 1) - 1) * (pagination?.pageSize ?? 10)

        const dataTables = data?.map((item, index) => {
          const key = index + 1
          return {
            _id: item?._id,
            key: key,
            index: startIndex + index + 1,
            name: item?.name ?? '-',
            type: item?.type,
            itemIds: item?.itemIds,
            status: item?.status,
            categoryName: 'Niềng Răng',
            purchaseUnit: 'Thùng',
            usingUnit: 'Bộ',
            minimumInstallmentPaymentInClinic: randomMoney(),
            minimumOneTimePayment: randomMoney(),
            endOfShiftPayment: randomMoney(),
            categoryId: item?.categoryid ?? '-',
          } as IDataTableItemManagement
        })

        return {
          pagination: {
            current: metadata?.currentPage ?? 1,
            pageSize: pagination?.pageSize,
            total: metadata?.totalItem ?? 0,
          },
          data: dataTables,
        } as IDataBaseTable<IDataTableItemManagement>
      }

      return initData
    } catch (error) {
      console.log({ error })

      return initData
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,
    handleTableChange,
    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getDataTable })

  const exportExcel = async () => {
    try {
      // do something
    } catch (error: any) {
      console.log(error)
      notificationController.error(error)
    } finally {
      fetchLoading(false)
    }
  }

  const handleChangeFilter = (filter: IFilterResponseItemManagement) => {
    fetch(tableData.pagination, filter)
  }

  const fetchNewData = () => {
    fetch(tableData.pagination, props)
  }

  const columns = useMemo(() => {
    type TypeColumn = IDataTableItemManagement

    const options: IBaseColumnReport<TypeColumn> = [
      convertColumnTable<TypeColumn>({
        title: t(R.strings.service),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          const isExpanded = expandedRowKeys.includes(record.key ?? 0)
          const _handleExpand = () => {
            return handleExpand(!isExpanded, record?.key)
          }

          let isNotHaveActionExpanded = true

          if (record?.type === TypeItemEnum.service) {
            isNotHaveActionExpanded = false
          }

          const style: React.CSSProperties | undefined = isExpanded
            ? { position: 'relative', marginTop: -16, paddingBottom: 15 }
            : undefined

          return (
            <ExpandedTableValue
              isExpanded={isExpanded}
              handleExpand={_handleExpand}
              isNotHaveActionExpanded={isNotHaveActionExpanded}
              isHaveAreaWhenNotHaveActionExpanded
              style={style}
              alignContainer="middle"
              valueCustom={
                <BaseRow gutter={[10, 10]} align={'middle'} wrap={false}>
                  <BaseCol>
                    <BaseAvatar
                      alt={record?.name ?? '-'}
                      style={{ borderRadius: BORDER_RADIUS }}
                      size={40}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseSpace size={5}>
                      <BaseText
                        children={record?.name ?? '-'}
                        fontWeight="semibold"
                        colorText="primaryColor"
                        fontSize="xs"
                        lineHeight="1.45"
                      />
                      <BaseRow gutter={[16, 16]} align={'middle'}>
                        <BaseCol>
                          <BaseText
                            children={`${t(R.strings.sku)}: PW0989980`}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        </BaseCol>
                        <BaseCol>
                          <BaseText
                            children={`${t(R.strings.barcode)}: 98099898`}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        </BaseCol>
                      </BaseRow>
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
              }
            />
          )
        },
      }),
    ]

    return [
      ...options,
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.category),
        key: 'categoryName',
        render: (_, record) => {
          return (
            <BaseText
              children={record.categoryName}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.item_management_content_type),
        key: 'categoryId',
        render: (_, record) => {
          return (
            <BaseText
              children={record.categoryId}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.item_management_content_purchase_unit),
        key: 'purchaseUnit',
        render: (_, record) => {
          return (
            <BaseText
              children={record.purchaseUnit}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.item_management_content_using_unit),
        key: 'usingUnit',
        render: (_, record) => {
          return (
            <BaseText
              children={record.usingUnit}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.minimum_one_time_payment),
        key: 'minimumOneTimePayment',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.minimumOneTimePayment ?? 0)}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.minimum_installment_payment_in_clinic),
        key: 'minimumInstallmentPaymentInClinic',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(
                record?.minimumInstallmentPaymentInClinic ?? 0,
              )}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.end_of_shift_payment),
        key: 'endOfShiftPayment',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={formatMoney(record?.endOfShiftPayment ?? 0)}
              fontWeight="medium"
              opacity="0.7"
              fontSize="xs"
            />
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: t(R.strings.status),
        key: 'status',
        render: (_, record) => {
          const data = DefineStatusProductService?.[record?.status]
          return (
            <StatusWrapper $backgroundImage={data?.backgroundImage}>
              <BaseText
                children={t(data.i18nKey)}
                colorText="backgroundColor"
                fontWeight="semibold"
              />
            </StatusWrapper>
          )
        },
      }),
      convertColumnTable<IDataTableItemManagement>({
        title: '',
        key: 'status',
        classNameWidthColumnOverwrite: 'number-column',
        fixed: 'right',
        render: (_, record) => {
          return (
            <BasePopover
              content={
                <BaseSpace>
                  <ButtonUpdate
                    title={t(R.strings.update)}
                    path={ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH.replace(
                      ':type',
                      record.type,
                    ).replace(':id', record._id)}
                  />
                  <DeleteItem
                    id={record._id}
                    fetchTable={fetchNewData}
                    name={record?.name}
                    type={record?.type}
                  />
                </BaseSpace>
              }
              trigger="click"
            >
              <BaseButton
                type={'text'}
                icon={<MoreOutlined rev={undefined} />}
              />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t, expandedRowKeys, fetchNewData])

  return {
    columns,
    tableData,
    handleTableChange,
    handleExpand,
    expandedRowKeys,
    exportExcel,
    handleResetExpand,
    fetch,
    fetchLoading,
    handleChangeFilter,
  }
}

const StatusWrapper = styled.div<{ $backgroundImage: string }>`
  background-image: ${props => props?.$backgroundImage};
  padding: 6px 12px;
  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`
