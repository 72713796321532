/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestPatientDebtContract } from 'app/api/accounting'
import { useBaseTableReportHook } from 'app/components/tables/BaseTableReport/hook'
import { Pagination } from 'parkway-web-common'
import { useCommonHook } from '../common-hook'
import { initialPagination } from './data'
import { useState } from 'react'

export const useTablePaymentTypeHook = props => {
  const [metadata, setMetadata] = useState({})
  const getData = async (pagination: Pagination, params) => {
    try {
      if (
        (params?.startDate && params?.endDate)
      ) {
        const response = await requestPatientDebtContract(
          {
            page: (pagination?.current || 0) - 1,
            pageSize: pagination?.pageSize,
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            paidTypes: params?.paidType?.join(','),
            location: params?.location,
            paymentMethod: params?.paymentMethod
          },
          { treatments: params?.treatments },
        )

        setMetadata(response?.metadata)
        return {
          pagination: { ...pagination, total: response?.metadata?.total },
          data: response?.data?.map((patient, index) => ({
            ...patient,
            key: index,
          })),
        }
      }

      return {
        pagination: pagination ?? initialPagination,
        data: [],
      }
    } catch (error) {
      return {
        pagination: pagination ?? initialPagination,
        data: [],
      }
    }
  }

  const {
    tableData,
    expandedRowKeys,
    handleExpand,

    handleResetExpand,
    fetch,
    fetchLoading,
  } = useBaseTableReportHook({ getTreeTableData: getData })

  const { columns, onClickExport } = useCommonHook({
    expandedRowKeys,
    fetch,
    fetchLoading,
    handleExpand,
    currentFilter: props,
  })

  const _onClickExport = async params => {
    await onClickExport(params)
  }

  return {
    columns,
    tableData,
    metadata,
    handleExpand,
    expandedRowKeys,

    handleResetExpand,
    fetch,
    fetchLoading,
    onClickExport: _onClickExport,
  }
}
