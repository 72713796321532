import { IJobTitleGroup } from 'app/api/jobtitlegroup/model/job-title-management'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Option } from 'app/components/common/BaseSelectSearch'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetJobTitleGroup } from 'app/react-query/hook/job-title-group'
import { IResponseQueryList } from 'app/react-query/model/common'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFilterProfile } from '../../type'

interface IProps {
  onChangeFilter?: (params: IFilterProfile) => void
}

const FilterJobTitleComponent = ({ onChangeFilter }: IProps) => {
  const { t } = useTranslation()
  const { data: jobTitleGroupsData, isLoading } = useGetJobTitleGroup()
  const { flattenDataList } = usePagination()
  const [jobTitleGroupId, setJobTitleGroupId] = useState<string | undefined>(
    undefined,
  )
  const [jobTitleIds, setJobTitleIds] = useState<string[]>([])

  const jobTitleGroups = useMemo(() => {
    const flattenData: IResponseQueryList<IJobTitleGroup[]> =
      flattenDataList(jobTitleGroupsData)
    return flattenData?.data ?? []
  }, [jobTitleGroupsData])

  const jobTiles = useMemo(() => {
    const itemGroup = jobTitleGroups?.find(itm => itm?._id === jobTitleGroupId)
    if (!itemGroup) return []

    return itemGroup?.jobTitles ?? []
  }, [jobTitleGroupId, jobTitleGroups])

  const onChangeGroupTitle = (value: string) => {
    setJobTitleGroupId(value)
    setJobTitleIds([])
  }

  const onChangeJobTitle = (values: string[]) => {
    setJobTitleIds(values)
  }

  useEffect(() => {
    onChangeFilter?.({
      group: jobTitleGroupId,
      jobTitles: jobTitleIds ?? [],
    })
  }, [jobTitleIds, jobTitleGroupId])

  return (
    <RootWrapper align={'middle'} gutter={[20, 20]}>
      <BaseCol xl={12}>
        <FormItem>
          <SelectBaseStyled
            placeholder={t(
              R.strings.job_title_content_please_select_job_title_group,
            )}
            onChange={value => onChangeGroupTitle(value as string)}
            value={jobTitleGroupId}
            allowClear
          >
            {jobTitleGroups?.map((item, index) => {
              return (
                <Option value={item._id} key={index}>
                  {item.name}
                </Option>
              )
            })}
          </SelectBaseStyled>
        </FormItem>
      </BaseCol>

      {jobTiles?.length ? (
        <BaseCol xl={12}>
          <FormItem>
            <SelectBaseStyled
              placeholder={t(R.strings.profile_content_please_select_job_title)}
              onChange={values => onChangeJobTitle(values as string[])}
              value={jobTitleIds}
              allowClear
              mode="multiple"
              maxTagCount={1}
            >
              {jobTiles?.map((item, index) => {
                return (
                  <Option value={item._id} key={index}>
                    {item.name}
                  </Option>
                )
              })}
            </SelectBaseStyled>
            {isLoading && <Loading />}
          </FormItem>
        </BaseCol>
      ) : null}
    </RootWrapper>
  )
}

export default FilterJobTitleComponent

const RootWrapper = styled(BaseRow)``

export const FormItem = styled(BaseForm.Item)``

export const SelectBaseStyled = styled(BaseSelect)`
`
