import { Pagination } from 'parkway-web-common'
import { IGeneralCostPlanTable } from '../common-model'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
}

export interface IDataCostPlanWeekly
  extends IGeneralCostPlanTable<IDataCostPlanWeekly> {
    quarter?: string
}

export interface IDataReportTable004 {
  data: IDataCostPlanWeekly[]
  pagination: Pagination
}

export const getTreeTableData = (
  pagination: Pagination,
): Promise<IDataReportTable004> => {
  return new Promise(res => {
    setTimeout(() => {
      res({
        data: [],
        pagination: { ...pagination, total: 8 },
      })
    }, 1000)
  })
}
