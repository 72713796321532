/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestExportPatientDebt } from 'app/api/accounting'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IParamsHookReport } from 'app/components/tables/BaseTableReport/type'
import { notificationController } from 'app/controllers/notification-controller'
import { Pagination, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ALink } from './styles'
import { DATE, NOTIFICATION_TYPE } from 'app/common/constants'
import { get } from 'lodash'
import { IGeneralDebtTable } from './common-model'
import { PaymentMethodTranslateEnum } from 'app/components/tables/BaseTableManagement/FilterDeptTable/type'
import { downloadNotificationController } from 'app/controllers/download-notification-controller'
import { handlePermission } from '../common'

export const useCommonHook = ({
  expandedRowKeys,
  fetchLoading,
}: {
  expandedRowKeys: number[]
  fetchLoading: (value: boolean) => void
  fetch: (pagination: Pagination, params?: IParamsHookReport) => void
  handleExpand: (isExpanded: boolean, key?: number) => void
  currentFilter?: IParamsHookReport
}) => {
  const { t } = useTranslation()

  const onClickExport = async params => {
    fetchLoading(true)
    try {
      if (!params?.startDate && !params?.endDate) {
        notificationController.error({
          message: t(R.strings.select_range_date_required),
        })
      } else {
        await requestExportPatientDebt(
          {
            keyword: params?.search,
            startDate: params?.startDate,
            endDate: params?.endDate,
            location: params?.location,
            paymentMethod: params?.paymentMethod
          },
          { treatments: params?.treatments },
        )
        
        await handleNotification()
      }
    } catch (error) {
      // console.log({ error })
      notificationController.error({
        message: t(R.strings.error),
      })
    }
    fetchLoading(false)
  }


  const handleNotification = async () => {
    const permissionNotification = await handlePermission()
    if (
      permissionNotification?.state === 'granted' &&
      permissionNotification?.status === true
    ) {
      return downloadNotificationController.waiting({
        duration: 0,
        key: NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT,
        message: t(R.strings.manage_customer_debt),
        description: t(R.strings.download_waiting),
        cancel: t(R.strings.cancel),
      })
    }

    if (!permissionNotification?.status)
      notificationController.warning({
        message: t(R.strings.download_warning),
        description: t(R.strings.download_warning_description),
      })

    notificationController.success({
      message: t(R.strings.success),
      description: t(R.strings.export_excel_sendmail_message),
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.date_time),
        key: 'date',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={moment(record?.date).format(DATE.DD_MM_YYYY_HH_MM)}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_patient_name),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <ALink
            onClick={e => {
              e.preventDefault()
              window.open(
                ENV_CONFIG.OLD_WEBSITE_ENDPOINT +
                  '/patients/details/' +
                  record?.patient?._id,
              )
            }}
          >
            {record?.patient?.name}
          </ALink>
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_patient_code),
        key: 'patient',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.patient?.id} type="Main" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.doctor),
        key: 'doctor',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.doctor?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_clinic_name),
        key: 'location',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.location?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.search_appointment_treatment),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => (
          <RenderValueTable value={record?.treatments?.name} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.payment_method),
        key: 'treatments',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={t(PaymentMethodTranslateEnum[String(record?.treatments?.paymentMethod)])} type="Base" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.debt),
        key: 'totalRemain',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.totalRemain} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.treatment_fee),
        key: 'totalNetAmount',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.totalNetAmount} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.paid),
        key: 'totalPaid',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.totalPaid} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.refund),
        key: 'totalRefund',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable value={record?.totalRefund} type="Money" />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_tip_start_date),
        key: 'dateStartProcedure',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          const dateStartProcedure = get(record, 'dateStartProcedure[0]', '');
          return (
            <RenderValueTable
              value={
                dateStartProcedure
                  ? moment(dateStartProcedure).format(DATE.DD_MM_YYYY)
                  : ''
              }
              type="Base"
            />
          )
        },
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_debt_tip_end_name),
        key: 'endProcedureTreatmentName',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => (
          <RenderValueTable
            value={record.endProcedureTreatmentName}
            type="Base"
          />
        ),
      }),
      convertColumnTable<IGeneralDebtTable>({
        title: t(R.strings.manage_customer_debt_tip_end_date),
        key: 'dateEndProcedure',
        classNameWidthColumnOverwrite: 'normal-column',
        render: (_, record) => {
          return (
            record.endProcedureTreatmentName &&
            record.dateEndProcedure && (
              <RenderValueTable
                value={moment(record.dateEndProcedure).format(DATE.DD_MM_YYYY)}
                type="Base"
              />
            )
          )
        },
      }),
    ]
  }, [t, expandedRowKeys])

  return {
    onClickExport,
    columns,
  }
}
